const REDIRECT_GOTO = process.env.REDIRECT_GOTO;

export const IS_PROD = process.env.ENVIRONMENT === 'production';
export const IS_NON_PROD = process.env.ENVIRONMENT !== 'production';

export const CACHED_DATA_MINUTES_TTL = 5;
export const MESSAGE_COUNTER_MINUTES_TTL = 10;
export const MESSAGE_COUNTER_EXPIRATION = 'message_counter_expiration';
export const DATA_LOAD_EXPIRATION = 'data_load_expiration';
export const INITIAL_DATA_LOAD = 'initial_data_load';
export const IS_TDOC_TOKEN_INVALID = 'is_tdoc_token_invalid';
export const ACCESS_TOKEN_PREV = 'access_token_prev';
export const MEMBER_AUTH_TOKEN_PREV = 'member_auth_token_prev';
export const MEMBER_ID_PREV = 'member_id_prev';
export const UN_LINKED_MEMBER = 'un_linked_member';
export const UN_LINKED_MEMBER_EXPIRATION = 'un_linked_member_expiration';
export const SEND_MESSAGE_ONCE = 'send-message-once';
export const ACCESS_TOKEN_TTL = 'acess_token_ttl';
export const EXPIRATION_TIME = 'expiration_time';
export const MEMBER_ID = 'member_id';
export const BUNDLE_IDENTIFIER = 'bundle_identifier';
export const TDOC_AUTH_TOKEN = 'member_auth_token';
export const MY_REFRESH_COOKIE = 'my_refreshToken';
export const ACCESS_TOKEN_COOKIE = 'access_token';
export const REFRESH_TOKEN_COOKIE = 'refresh_token';
export const USER_LOCALE_COOKIE = 'user_preferred_language';
export const FOOD_TOUR_KEY = 'has-seen-food-tour';
export const NEXT_COACHING_SESSION = 'next-coaching-session';
export const COACH_SESSION = 'coach-session';
export const HOME = 'home';
export const ACHIEVED_WEIGHT_COOKIE = 'has-seen-achieved-weight';
export const HF_RETAKE_ALERT_ID = 'retake-alert-id';
export const PIWIK_ENABLED = 'piwik-enabled';
export const COUNTRY_CODES = {
    CANADA: 'CA',
};
export const AUTHENTICATION = 'authentication';
export const HF_RETAKE_REACHOUT_NOT_NEEDED_KEY =
    'has-seen-retake-reachout-not-needed-card';
export const HF_RETAKE_LINK_TIMED_OUT_KEY = 'has-seen-retake-link-timeout';
export const MEMBER_SUPPORT_EMAIL = 'membersupport@livongo.com';
export const ONEAPP_SUPPORT_EMAIL = 'membersupport@teladochealth.com';
export const TWILIO_SMS_PHONE_NUMBER = '844-981-4079';
export const TRIGGER_MESSAGE_COUNTER = [
    '/consult/new#ConsultationSuccess ',
    '/cancel#success',
    '/message-center/',
];
export const MEMBER_COMMUNICATION_POLICY = {
    'en-US':
        'https://content.livongo.com/docs/Livongo_Member_Communication_Policy.pdf',
    'es-US':
        'https://content.livongo.com/docs/Livongo_Member_Communication_Policy.pdf',
    // TODO: update to Spanish Member Communication Policy once it's translated
};
export const MEMBER_SUPPORT_URL = {
    'en-US':
        'https://livongo.zendesk.com/hc/en-us/categories/115001480808-Member-Self-Help-Center',
    'es-US':
        'https://livongo.zendesk.com/hc/es-us/categories/115001480808-Member-Self-Help-Center',
};
export const ZENDESK_URL = {
    'en-US': 'https://livongo.zendesk.com/hc/en-us',
    'es-US': 'https://livongo.zendesk.com/hc/es-us',
};
export const TERMS_OF_SERVICE_URL = {
    'en-US': `${REDIRECT_GOTO}/terms-of-service?locale=en-US`,
    'es-US': `${REDIRECT_GOTO}/terms-of-service?locale=es-US`,
};
export const PRIVACY_POLICY_URL = {
    'en-US': `${REDIRECT_GOTO}/web-privacy-policy?locale=en-US`,
    'es-US': `${REDIRECT_GOTO}/web-privacy-policy?locale=es-US`,
    'en-CA': `${REDIRECT_GOTO}/web-privacy-policy?locale=en-CA`,
    'fr-CA': `${REDIRECT_GOTO}/web-privacy-policy?locale=fr-CA`,
    // TODO: Uncomment spanish privacy policy link after document is updated at the end of q1 2021
    // es: 'https://teladochealth.com/es/privacy-policy/',
};
export const PRIVACY_PRACTICES_URL = {
    'en-US': `${REDIRECT_GOTO}/web-privacy-practice?locale=en-US`,
    'es-US': `${REDIRECT_GOTO}/web-privacy-practice?locale=es-US`,
};
export const ACCESSIBILITY_URL = {
    'en-CA': `${REDIRECT_GOTO}/accessibility?locale=en-CA`,
    'fr-CA': `${REDIRECT_GOTO}/accessibility?locale=fr-CA`,
};
export const LEGAL_DISCLAIMER_URL = {
    'en-CA': `${REDIRECT_GOTO}/legal-disclaimer?locale=en-CA`,
    'fr-CA': `${REDIRECT_GOTO}/legal-disclaimer?locale=fr-CA`,
};
export const NON_DISCRIMINATION_URL = {
    'en-US': `${REDIRECT_GOTO}/notice-of-non-discrimination?locale=en-US`,
    'es-US': `${REDIRECT_GOTO}/notice-of-non-discrimination?locale=es-US`,
};
export const COPA_PRIVACY_URL = `${REDIRECT_GOTO}/children-online-privacy`;
export const NAME_INPUT_PATTERN = "^(?![- '.]+$)[-0-9A-zÀ-ž '.]*$"; // https://regex101.com/r/1D9Myt/97
export const ADDRESS_INPUT_PATTERN = '[-.0-9A-zÀ-ž #]+';
export const EMAIL_INPUT_MAX_LENGTH = 100;
export const CONFETTI_COLORS = [
    '#0060FC',
    '#36D663',
    '#86D5DF',
    '#66B5F7',
    '#E80048',
    '#34A847',
    '#E47600',
    '#845EF7',
    '#149DB1',
];
export const PHONE_INPUT_PATTERN =
    '^[(]?([0-9]{3})[)]?([-. ])?([0-9]{3})([-. ])?([0-9]{4})$';
export const FORMAT_DATETIME_NO_TZ = "yyyy-MM-dd'T'HH:mm:ss";
export const REFERRER_REGEX = /^https:\/\/(\w*\.)*livongo\.com\//;
export const BANNER_LAUNCH_DATE = {month: 4, day: 15};
export const BANNER_EXPIRY_DATE = {month: 5, day: 30};
export const VIDEO_URL = 'https://member.teladoc.com/test_video';
export const CONSENT_AGREEMENT_URL =
    'https://www.teladoc.com/informed-consent-agreement';
export const CARE_RECIPIENT_AGREEMENT_URL =
    'https://member.teladoc.com/terms/cr_agreement.html';
export const FAQ_URL = 'https://www.teladoc.com/faq/';
export const VIDEO_PATH = '/video';
export const REMINDER_ID = 'reminder';
export const NAV_MENU = {
    Home: {
        id: 'home',
    },
    Programs: {
        id: 'programs',
    },
    HealthInfo: {
        id: 'healthInfo',
    },
    Message: {
        id: 'messages',
    },
    MessageCenter: {
        id: 'messageCenter',
    },
    Account: {
        id: 'account',
    },
    Sitemap: {
        id: 'sitemap',
    },
    Language: {
        id: 'language',
    },
};
export const FOOTER_CONTENT_STATE = {
    TeleMed: {
        id: 'teleHealth',
    },
    MyStrength: {
        id: 'myStrength',
    },
    ConditionManagement: {
        id: 'conditionManagement',
    },
};
export const BREAKPOINT_SIZE = {
    Large: 'lg',
    Small: 'sm',
};
export const CONDITION_MANAGEMENT = 'conditionManagement';
export const MENTAL_HEALTH = 'mentalHealth';
export const MYSTRENGTH_GUIDE_MESSAGE_FLAG = 'isGuideMessages=true';
export const MYSTRENGTH_ONEAPP_FLAG = 'isOneApp=true';
export const MESSAGE_SERVICE_TYPES = {
    ChronicCare: 'chronicCare',
    MentalHealth: 'mentalHealth',
    CareTeam: 'careTeam',
};
export const INBOX_SERVICE_TYPES = {
    CareTeam: 'telemed',
    ChronicCare: 'chronicCare',
    MentalHealth: 'myStrength',
};
export const SIDEBAR_MENU = {
    Weight: 'weight',
    Coaching: 'coaching',
};
export const OFFERED_PROGRAMS = {
    PrimaryCarePlan: 'Primary Care plan',
    PrimaryCareTeam: 'Primary Care team',
    ExpertMedicalOpinion: 'Expert Medical Opinion cases',
    Telemed: 'Telemed',
    ConditionManagement: 'Condition Management',
    MentalHealth: 'Mental Health Support',
    PrimaryCareVitals: 'Primary Care vitals',
};
export const NONSTANDARD_MENTAL_HEALTH_ROUTES = {
    Onboarding: 'onboarding',
    Reassessment: 'reassessment',
    Interstitial: 'interstitial',
    Login: 'login',
    Base: 'mentalHealthBase',
    WaitingRoomHash: '#WaitingRoom',
};
export const TRACK_EVENTS_MAP = {
    navigationButtonClicked: 'navigation_button_clicked',
};
export const SCREEN_TRACK_EVENTS = {
    Display: 'display',
    Click: 'initiated',
};
export const ACTION_TRACKING = {
    initiated: {
        amplitudeEvent: {
            name: TRACK_EVENTS_MAP.navigationButtonClicked,
            properties: {
                category: 'navigation',
            },
        },
    },
};
export const EVENT_PROPS = {
    Home: 'Home',
    Global: 'Global',
    Programs: 'Programs',
    HealthInfo: 'Health Info',
    Account: 'Account',
    Logo: 'logo',
    Messages: 'Messages',
    ProductLine: 'product_line',
    PrimaryCare: 'Primary Care',
    ExpertMedicalOpinion: 'Expert Medical Opinion',
    CareTeam: 'Care team',
    MentalHealthGuides: 'Mental Health guides',
    CCMCoaches: 'Condition Management coaches',
    CCM: 'Chronic Condition Management',
    ConditionManagement: 'Condition Management',
    MentalHealth: 'Mental Health',
    Profile: 'Profile',
    SecuritySignIn: 'Security and sign in',
    CommPreferences: 'Communication preferences',
    EmergencyContacts: 'Emergency contacts',
    Family: 'Family',
    BillingCoverage: 'Billing and coverage',
    GetHelp: 'Get help',
    SignOut: 'Sign out',
    PastTeleVisits: 'Past telehealth visits',
    TeleDocuments: 'Telehealth visit documents',
    MedicalHistory: 'Medical history',
    PrimaryCarePlan: 'Primary Care plan',
    PrimaryCareTeam: 'Primary Care team',
    ExpertMedRequests: 'Expert Medical Opinion requests',
    Pharmacies: 'Pharmacies',
    ExternalProviders: 'External providers',
    Inbox: 'inbox',
};
