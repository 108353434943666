import mixpanel from 'mixpanel-browser';
import isNil from 'lodash/isNil';
import cloneDeep from 'lodash/cloneDeep';
import {trackScreenEvent} from '@td/websdk';
import {chatToggle} from '@teladoc/fe-ccm/ui/chat/chat-actions';
import {appUpdate} from '@teladoc/fe-ccm/ui/app/app-actions';
import CCMCommonUtils from '@teladoc/fe-ccm/ui/common/utilities/common-utils';
import StorageUtils from '@livongo/utilities/system/storage';
import {
    HOME,
    EVENT_PROPS,
    ACTION_TRACKING,
    BREAKPOINT_SIZE,
    USER_LOCALE_COOKIE,
    SCREEN_TRACK_EVENTS,
    MESSAGE_SERVICE_TYPES,
    TRIGGER_MESSAGE_COUNTER,
} from '@/config';
import {
    programRoutes,
    pharmacyRoutes,
    healthMenuRoutes,
    getPrimaryRouteById,
    telemedHealthInfoRoute,
} from '@/router/routes';
import OneAppUserAPI from '../user/user-oneapp-api';
import ProgramUtils from './program-utils';

const LOCALE_REGEX = /^[a-zA-Z]{2}-[a-zA-Z]{2}$/;

const showProgramsMenu = ({hasConditionManagement, hasMentalHealth}) => {
    return hasConditionManagement || hasMentalHealth;
};

const updateProgramsMenuRoutes = ({programs, appState}) => {
    const updatedRoutes = [];
    const CCMProfilePrefRoute = getPrimaryRouteById('ccmProgramsRedirect');
    const CCMSSORedirectRoute = getPrimaryRouteById('ccmSso');
    let CCMRoute = programRoutes[0];
    const MHRoute = programRoutes[1];

    if (ProgramUtils.userHasLivongoAccount()) {
        if (!ProgramUtils.userHasActiveCCMProgram(programs)) {
            CCMRoute = CCMProfilePrefRoute;
        }
    } else {
        CCMRoute = CCMSSORedirectRoute;
    }

    if (appState.hasConditionManagement) {
        updatedRoutes.push(CCMRoute);
    }

    if (appState.hasMentalHealth) {
        updatedRoutes.push(MHRoute);
    }

    return updatedRoutes;
};

const updateHealthInfoMenuRoute = ({hasTelemed}) => {
    const updatedRoute = [];
    const isPLIBrand = OneAppUserAPI.isPLIBrand();
    const routesToSuppress = {
        careTeam: !hasTelemed?.primaryCareTeam,
        carePlan: !hasTelemed?.primaryCarePlan,
    };

    updatedRoute.push(...healthMenuRoutes);

    if (hasTelemed?.primaryCareVitals) {
        updatedRoute.push(telemedHealthInfoRoute[0]);
    }

    if (hasTelemed?.expertOpinionCases) {
        updatedRoute.push(telemedHealthInfoRoute[1]);
    }

    updatedRoute.push(...pharmacyRoutes);

    if (isPLIBrand) {
        return updatedRoute.filter(item => !routesToSuppress[item.id]);
    }

    return updatedRoute;
};

// this method returns amplitude updated event properties
const setTrackingData = (productLine, level, button, props = {}) => {
    const actionTracking = cloneDeep(ACTION_TRACKING);

    actionTracking.initiated.amplitudeEvent.properties = {
        ...actionTracking.initiated.amplitudeEvent.properties,
        ...{
            [EVENT_PROPS.ProductLine]: productLine,
            level,
            button,
            ...props,
        },
    };

    trackScreenEvent(actionTracking, SCREEN_TRACK_EVENTS.Click);
};

const onChatCCMOpen = ({channels, dispatch}) => {
    dispatch(chatToggle());
    mixpanel.track('navigation.button.clicked', {
        level: 2,
        button: 'Condition Management coaches',
    });

    setTrackingData(EVENT_PROPS.CCM, 2, EVENT_PROPS.CCMCoaches);
};

const updateSidebar = (showSidebar, dispatch) => {
    dispatch(appUpdate({isOneAppSidebar: showSidebar}));
};

const setBreakpoint = (breakpoint, dispatch) => {
    if (
        window.matchMedia('(min-width: 768px)').matches &&
        breakpoint !== BREAKPOINT_SIZE.Large
    ) {
        dispatch(appUpdate({breakpoint: BREAKPOINT_SIZE.Large}));
    } else if (
        window.matchMedia('(max-width: 767px)').matches &&
        breakpoint !== BREAKPOINT_SIZE.Small
    ) {
        dispatch(appUpdate({breakpoint: BREAKPOINT_SIZE.Small}));
    }
};

const getMessageEligibilityStatus = (
    hasChatAvailable,
    hasMHGuideAvailable,
    hasCareTeam
) => {
    if (hasChatAvailable && !hasMHGuideAvailable && !hasCareTeam) {
        return MESSAGE_SERVICE_TYPES.ChronicCare;
    } else if (!hasChatAvailable && hasMHGuideAvailable && !hasCareTeam) {
        return MESSAGE_SERVICE_TYPES.MentalHealth;
    } else if (!hasChatAvailable && !hasMHGuideAvailable && hasCareTeam) {
        return MESSAGE_SERVICE_TYPES.CareTeam;
    } else {
        return null;
    }
};

const isOneAppLocaleCookieSet = () =>
    !isNil(CCMCommonUtils.getCookie({key: USER_LOCALE_COOKIE}));

const setOneAppLocaleCookie = value =>
    CCMCommonUtils.setCookie({key: USER_LOCALE_COOKIE, value});

const getOneAppLocaleCookie = () => {
    let locale = StorageUtils.get({
        key: USER_LOCALE_COOKIE,
        type: 'cookie',
        useNative: true,
        parse: false,
    });

    const defaultLocale = 'en-US';

    if (isNil(locale) || !LOCALE_REGEX.test(locale)) {
        return defaultLocale;
    }

    try {
        locale = locale?.split('-');
        locale?.splice(1, 1, locale[1].toUpperCase());
        locale = locale.join('-');
    } catch (e) {
        return defaultLocale;
    }

    try {
        // see if we have a translation file for this locale
        require(`@teladoc/fe-i18n/locales/${locale}/app.json`);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(`Translation file unavailable for locale ${locale}`);
        locale = defaultLocale;
    }

    return locale;
};

const getLogoUrlForBrand = ({branding, width, height}) => {
    if (branding && branding?.brand_code) {
        return CCMCommonUtils.createCloudinaryUrl({
            src: `pli/brands/${branding.brand_code}/logo.svg`,
            width,
            height,
        });
    }
};

const getFaviconUrlForBrand = ({branding, width, height}) => {
    if (branding && branding?.brand_code) {
        return CCMCommonUtils.createCloudinaryUrl({
            src: `pli/brands/${branding.brand_code}/favicon.ico`,
            width,
            height,
        });
    }
};

const getHeaderTitleForMatchingScreen = (screenName, screenHeadersObject) => {
    let headerTitle = null;

    // Check if the screen name starts with or contains any of the keys
    Object.keys(screenHeadersObject).forEach(key => {
        if (screenName.startsWith(key) || screenName.includes(key)) {
            headerTitle = screenHeadersObject[key];
        }
    });

    return headerTitle;
};

const isOneAppDashboard = url => {
    const noProtocal = url.replace('https://', '').replace('http://', ''); // remove protocals;
    const urlArray = noProtocal?.split('/');

    if (urlArray.length > 1) {
        return urlArray[1] === HOME;
    }

    return false;
};

const validateRedirectURL = locationWithHash => {
    return (
        TRIGGER_MESSAGE_COUNTER?.find(path => locationWithHash?.includes(path))
            ?.length > 0
    );
};

const getCountry = value => {
    if (!value) {
        return 'USA';
    }
    const hyphenIndex = value.indexOf('-');

    if (hyphenIndex !== -1) {
        const country = value.substring(hyphenIndex + 1);

        return country === 'US' ? 'USA' : country;
    }

    return 'USA';
};

const CommonUtils = {
    getCountry,
    isOneAppLocaleCookieSet,
    setOneAppLocaleCookie,
    onChatCCMOpen,
    updateSidebar,
    setBreakpoint,
    setTrackingData,
    showProgramsMenu,
    isOneAppDashboard,
    validateRedirectURL,
    getOneAppLocaleCookie,
    getLogoUrlForBrand,
    getFaviconUrlForBrand,
    updateProgramsMenuRoutes,
    updateHealthInfoMenuRoute,
    getMessageEligibilityStatus,
    getHeaderTitleForMatchingScreen,
    isIE: CCMCommonUtils.isIE,
    setLocalStorage: CCMCommonUtils.setLocalStorage,
    getLocalStorage: CCMCommonUtils.getLocalStorage,
    removeLocalStorage: CCMCommonUtils.removeLocalStorage,
    setCookie: CCMCommonUtils.setCookie,
    removeCookie: CCMCommonUtils.removeCookie,
    getCookie: CCMCommonUtils.getCookie,
    unicodeToChar: CCMCommonUtils.unicodeToChar,
    formatPhoneNumber: CCMCommonUtils.formatPhoneNumber,
    isFeatureEnabled: CCMCommonUtils.isFeatureEnabled,
    generateOneAppParams: CCMCommonUtils.generateOneAppParams,
};

export default CommonUtils;
