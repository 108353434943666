import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ApiError from '@teladoc/fe-ccm/ui/common/api-error/ApiError';
import Channels from '@teladoc/fe-ccm/ui/chat/channels/Channels';
import Messages from '@teladoc/fe-ccm/ui/chat/messages/Messages';
import {chatSetCurrentChannel} from '@teladoc/fe-ccm/ui/chat/chat-actions';
import Loader from '@teladoc/pulse/ui/Loader';
import css from './ConditionManagement.scss';

const ConditionManagement = () => {
    const dispatch = useDispatch();
    const {channels, currentChannel, isConnected, isError} = useSelector(
        state => state.chat
    );
    const onChannelSelected = channel => {
        dispatch(chatSetCurrentChannel(channel));
    };

    useEffect(() => {
        if (!channels) return;

        return () => {
            dispatch(chatSetCurrentChannel(null));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={css.ConditionManagementContainer}>
            {!isConnected && <Loader />}
            {isError && <ApiError />}
            {isConnected &&
                (currentChannel ? (
                    <Messages channel={currentChannel} />
                ) : (
                    <Channels
                        data={channels}
                        onChannelSelected={onChannelSelected}
                    />
                ))}
        </div>
    );
};

export default ConditionManagement;
