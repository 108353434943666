import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import CommonUtils from './utilities/common-utils';

export default function useDataTheme() {
    const {branding, isOneApp} = useSelector(state => state.app);
    const bundleIdentifier = CommonUtils.getCookie({key: 'bundle_identifier'});

    useEffect(() => {
        const getDataTheme = () => {
            let theme = '';

            if (bundleIdentifier === 'com.teladoc') {
                theme = 'teladoc';
            } else if (bundleIdentifier && bundleIdentifier !== 'com.teladoc') {
                theme = 'pli';
            }

            if (isOneApp && branding?.data_theme) {
                theme = branding?.data_theme;
            }

            return theme;
        };

        const dataTheme = getDataTheme();

        if (dataTheme) {
            document.documentElement.setAttribute('data-theme', dataTheme);
        }
    }, [branding, isOneApp, bundleIdentifier]);
}
