import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {messageCenterRoutes} from '@/router/routes';
import {INBOX_SERVICE_TYPES} from '@/config';

export const useMessageCenterRoutes = () => {
    const {t} = useTranslation();
    const {
        hasCareTeam,
        hasMentalHealth,
        hasChatAvailable,
        hasMHGuideAvailable,
        hasConditionManagement,
    } = useSelector(state => state.app);
    const mhAvailable = hasMentalHealth && hasMHGuideAvailable;
    const cmAvailable = hasConditionManagement && hasChatAvailable;

    const getActiveMessageCenterRoutes = (routes = messageCenterRoutes) => {
        const isMessageAvailable = mhAvailable || cmAvailable || hasCareTeam;

        if (!isMessageAvailable) return [];

        const ROUTES_ACCESS = {
            [INBOX_SERVICE_TYPES.CareTeam]: hasCareTeam,
            [INBOX_SERVICE_TYPES.ChronicCare]: cmAvailable,
            [INBOX_SERVICE_TYPES.MentalHealth]: mhAvailable,
        };

        return routes.filter(({id}) => ROUTES_ACCESS[id]);
    };

    const getActiveRoutesAsString = () => {
        const routes = getActiveMessageCenterRoutes();

        return routes.reduce((acc, {id}, index) => {
            // eslint-disable-next-line
            return (acc += `${t(`messageCenter.tabs.${id}`)}${
                index !== routes.length - 1 ? ', ' : ''
            }`);
        }, '');
    };

    return {getActiveMessageCenterRoutes, getActiveRoutesAsString};
};
