/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable no-sequences */
/* eslint-disable prefer-rest-params */
/* eslint-disable func-names */
/* eslint-disable no-unused-expressions */

import {PIWIK_ENABLED, IS_PROD} from '@/config';
import CommonUtils from '@/utils/common-utils';

const DATA_LAYER_NAME = 'dataLayer';

const PIWIKUtils = {
    init(window, document, piwikSiteId) {
        (window[DATA_LAYER_NAME] = window[DATA_LAYER_NAME] || []),
            window[DATA_LAYER_NAME].push({
                start: new Date().getTime(),
                event: 'stg.start',
            });
        const scripts = document.getElementsByTagName('script')[0];
        const tags = document.createElement('script');

        function stgCreateCookie(a, b, c) {
            let d = '';

            if (c) {
                const e = new Date();

                e.setTime(e.getTime() + 24 * c * 60 * 60 * 1e3),
                    (d = `; expires=${e.toUTCString()}`);
            }
            document.cookie = `${a}=${b}${d}; path=/`;
        }
        const isStgDebug =
            (window.location.href.match('stg_debug') ||
                document.cookie.match('stg_debug')) &&
            !window.location.href.match('stg_disable_debug');

        stgCreateCookie('stg_debug', isStgDebug ? 1 : '', isStgDebug ? 14 : -1);
        const qP = [];
        const qPString = qP.length > 0 ? `?${qP.join('&')}` : '';

        (tags.async = !0),
            (tags.src = `//teladoc-private.piwik.pro/containers/${piwikSiteId}.js${qPString}`),
            scripts.parentNode.insertBefore(tags, scripts);
        !(function (a, n, i) {
            a[n] = a[n] || {};
            for (let c = 0; c < i.length; c++)
                !(function (i) {
                    (a[n][i] = a[n][i] || {}),
                        (a[n][i].api =
                            a[n][i].api ||
                            function () {
                                const a = [].slice.call(arguments, 0);

                                typeof a[0] === 'string' &&
                                    window[DATA_LAYER_NAME].push({
                                        event: `${n}.${i}:${a[0]}`,
                                        parameters: [].slice.call(arguments, 1),
                                    });
                            });
                })(i[c]);
        })(window, 'ppms', ['tm', 'cm']);
    },

    loadScript(piwikSiteId) {
        const noscript = document.createElement('noscript');
        const iframe = document.createElement('iframe');

        iframe.setAttribute(
            'src',
            `//teladoc-private.piwik.pro/containers/${piwikSiteId}/noscript.html`
        );
        iframe.setAttribute('height', '0');
        iframe.setAttribute('width', '0');
        iframe.setAttribute('style', 'display: none; visibility: hidden');

        noscript.appendChild(iframe);
        document.body.appendChild(noscript);
    },

    buildTrackingEvent({eventName, userId, eventAction = 'Page Visit'}) {
        if (!(IS_PROD || CommonUtils.getCookie({key: PIWIK_ENABLED}))) {
            return;
        }

        const piwikEvent = {
            idsite: process.env.PIWIK_SITE_ID,
            apiv: 1,
            cdt: Date(),
            dimension2: 'web',
            e_a: eventAction,
            e_c: 'ONEAPP',
            e_n: eventName,
            rand: Math.floor(Math.random() * 10000),
            rec: 1,
            send_image: '0',
            uid: userId,
            event: 'oneapp.programs',
        };

        if (window[DATA_LAYER_NAME] && Array.isArray(window[DATA_LAYER_NAME])) {
            window[DATA_LAYER_NAME].push(piwikEvent);
        } else {
            window[DATA_LAYER_NAME] = [];
        }
    },
};

export default PIWIKUtils;
