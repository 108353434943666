import APIUtils from '@livongo/utilities/system/api';
import ProtoUtils from '../common/utilities/proto-utils';

const {
    getHeaders,
    Biometric: {
        AlertFlowClient,
        ListFlowStagesRequest,
        UpdateAlertStageRequest,
        QuestionAnswer,
    },
    Google: {Int64Value},
} = ProtoUtils;
/*
For more details on the API
https://confluence.teladoc.net/display/LVGO/Complete+Onboarding+REST+APIs
https://confluence.teladoc.net/display/LVGO/Onboarding+flow+statues+and+APIs
*/

/**
 * OnboardingAPI is an object that provides methods for managing the onboarding process.
 *
 * @namespace OnboardingAPI
 */
const OnboardingAPI = {
    /*  get the display status for onboarding
         return {
            showFlow: Indicates if the onboarding flow should be displayed or not.
            flowName: The onboarding flow name for member.
            showHomeCard: Indicates if the program highlights card should be displayed or not.
            showOneAppCard: Indicates if the OneApp program highlights card should be displayed or not.
        }
    */
    display() {
        return APIUtils.get(
            `${process.env.SERVICE_URL}/service-journey-guidance/v1/users/me/onboarding/display`
        ).then(response => response);
    },
    /**
     * Saves the flow status for onboarding.
     * Available status: SKIPPED, COMPLETED, CARD_DISMISSED
     *
     * @param {Object} options - The options for saving the flow status.
     * @param {string} options.status - The status of the flow.
     * @param {string} options.flowName - The name of the flow.
     * @returns {Promise} A promise that resolves with the response from the API.
     */
    saveFlowStatus({status, flowName}) {
        return APIUtils.post(
            `${process.env.SERVICE_URL}/service-journey-guidance/v1/users/me/onboarding/status`,
            {
                status,
                flowName,
            }
        ).then(response => response);
    },
    /**
     * Deletes the onboarding status for the current user.
     * This api will reset all onboarding status regardless of flow names.
     * It is for testing only and is disabled on PROD.
     *
     * @returns {Promise} A promise that resolves when the onboarding status is successfully deleted.
     */
    delete() {
        return APIUtils.delete(
            `${process.env.SERVICE_URL}/service-journey-guidance/v1/users/me/onboarding/status`
        );
    },
    /**
     * Lists the flow stages.
     *
     * @param {Array<string>} flowTypes - The flow types.
     * @returns {Promise} - A promise that resolves with the list of flow stages.
     */
    listFlowStages(flowTypes) {
        const request = new ListFlowStagesRequest();

        request.setName('user/liv/me/flowStages');
        request.setFlowTypesList(flowTypes);

        return AlertFlowClient.listFlowStages(request, getHeaders());
    },
    /**
     * Updates the flow stage for onboarding. (orginal)
     *
     * @param {string} question - The question code.
     * @param {string} answer - The answer code.
     * @param {number} alertId - The alert ID.
     * @returns {Promise} - A promise that resolves with the updated alert stage.
     */
    updateFlowStage(question, answer, alertId) {
        const request = new UpdateAlertStageRequest();
        const questionData = new QuestionAnswer();

        questionData.setQuestionCode(question);
        questionData.setAnswerCode(answer);

        request.setName('user/liv/me/alertStage');
        request.setQuestionAnswer(questionData);
        request.setAlertId(new Int64Value().setValue(alertId));

        return AlertFlowClient.updateAlertStage(request, getHeaders());
    },
    /**
     * Update the status of the flow that passed in the [param] has additional feature.
     *
     * @param {string} question - The question code.
     * @param {string} answer - The answer code.
     * @param {number} alertId - The alert ID.
     * @returns {Promise} - A promise that resolves with the updated alert stage.
     */
    updateFlowStageV2(question, answer, alertId) {
        const request = new UpdateAlertStageRequest();
        const questionData = new QuestionAnswer();

        questionData.setQuestionCode(question);
        questionData.setAnswerCodesList([answer]);

        request.setName('user/liv/me/alertStage');
        request.setQuestionAnswer(questionData);
        request.setAlertId(new Int64Value().setValue(alertId));

        return AlertFlowClient.updateAlertStage(request, getHeaders());
    },
};

export default OnboardingAPI;
