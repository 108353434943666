import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import CommonUtils from './utilities/common-utils';

const faviconSizes = [16, 32, 192];

export default function useFavicon() {
    const {branding, isOneApp} = useSelector(state => state.app);

    useEffect(() => {
        const getUrl = size => {
            if (isOneApp && branding?.brand_code) {
                return CommonUtils.getFaviconUrlForBrand({
                    branding,
                    width: size,
                    height: size,
                });
            }
        };

        faviconSizes.forEach(size => {
            const favicon = document.getElementById(`favicon${size}`);

            favicon.href = getUrl(size);
        });
    }, [branding, isOneApp]);
}
