import {createStore, applyMiddleware} from 'redux';
import thunkMiddleware from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';
import {composeWithDevTools} from 'redux-devtools-extension';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from '@teladoc/fe-ccm/ui/store/reducers';

let store;
const IS_PROD = process.env.ENVIRONMENT === 'production';
const persistConfig = {
    key: 'oneapp',
    storage,
};

export default function create(initialState) {
    const persistedReducer = persistReducer(persistConfig, rootReducer);

    store = createStore(
        persistedReducer,
        initialState,
        composeWithDevTools(applyMiddleware(thunkMiddleware, promiseMiddleware))
    );

    const persistor = persistStore(store);

    return {store, persistor};
}

export const getStore = () => {
    // window object to get the store details.
    if (!IS_PROD) {
        window.CCMStore = store.getState();
    }

    return store;
};
