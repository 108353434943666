import size from 'lodash/size';
import OneAppUserAPI from '../user/user-oneapp-api';

const userHasActiveCCMProgram = programs => {
    if (!programs) {
        return false;
    }
    const filteredPrograms = programs.filter(
        program =>
            program.id !== 'behavioralHealth' && program.status === 'ACTIVE'
    );

    return size(filteredPrograms) >= 1;
};

const userHasActiveMHProgram = programs => {
    if (!programs) {
        return false;
    }
    const filteredPrograms = programs.filter(
        program =>
            program.id === 'behavioralHealth' && program.status === 'ACTIVE'
    );

    return size(filteredPrograms) >= 1;
};

const userHasLivongoAccount = () => {
    return Boolean(OneAppUserAPI.getCurrentCcmAccessToken());
};

const ProgramUtils = {
    userHasActiveCCMProgram,
    userHasActiveMHProgram,
    userHasLivongoAccount,
};

export default ProgramUtils;
