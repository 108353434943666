import {INBOX_SERVICE_TYPES} from '@/config';
import {getPrimaryRouteById} from '@/router/routes';
import Websdk from '@/app/Websdk';

const Telemed = () => {
    const path = getPrimaryRouteById(INBOX_SERVICE_TYPES.CareTeam).path;
    const browserBaseUrl = `message-center/telemed`;
    const inboxWebConfig = {
        browserBaseUrl,
        useDefaultBrowserTitle: false,
    };

    return <Websdk path={path} inboxWebConfig={inboxWebConfig} />;
};

export default Telemed;
