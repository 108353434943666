import {useEffect} from 'react';
import {useSelector} from 'react-redux';

export default function useUpdateThemeColors() {
    const {branding, isOneApp} = useSelector(state => state.app);

    useEffect(() => {
        if (isOneApp && branding?.colors) {
            const mode = document.documentElement.getAttribute('data-mode');
            const theme = document.documentElement.getAttribute('data-theme');

            const colorMapping = {
                '--color-brand-primary1': 'primary_1',
                '--color-brand-primary2': 'primary_2',
                '--color-brand-primary3': 'primary_3',
                '--color-brand-secondary1': 'secondary_1',
                '--color-brand-secondary2': 'secondary_2',
                '--color-brand-secondary3': 'secondary_3',
            };

            let cssText = '';

            Object.entries(colorMapping).forEach(([cssVar, brandColorKey]) => {
                const cssValue = branding.colors[mode][brandColorKey];

                cssText += `${cssVar}: ${cssValue};\n`;
            });

            const selector = `:root[data-theme="${theme}"][data-mode="${mode}"]`;
            const existingStyleTag = document.querySelector(`style${selector}`);

            if (existingStyleTag) {
                existingStyleTag.textContent = `${selector} { ${cssText} }`;
            } else {
                const newStyleTag = document.createElement('style');

                newStyleTag.textContent = `${selector} { ${cssText} }`;
                document.head.appendChild(newStyleTag);
            }
        }
    }, [branding, isOneApp]);
}
