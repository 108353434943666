import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';
import {useIdleTimer} from 'react-idle-timer';
import {Suspense, useState, useEffect, useCallback, useRef, lazy} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {cnb} from 'cnbuilder';
import camelCase from 'lodash/camelCase';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import startCase from 'lodash/startCase';
import addMinutes from 'date-fns/addMinutes';
import isBefore from 'date-fns/isBefore';
import mixpanel from 'mixpanel-browser';
import {appUpdate} from '@teladoc/fe-ccm/ui/app/app-actions';
import Loader from '@teladoc/fe-ccm/ui/loader/Loader';
import ApptentiveUtils from '@teladoc/fe-ccm/ui/common/apptentive/apptentive-utils';
import {
    chatToggle,
    chatConnect,
    chatSetCurrentChannel,
    chatUpdateUnreadCount,
} from '@teladoc/fe-ccm/ui/chat/chat-actions';
import ReactAxeCore from '@teladoc/fe-ccm/ui/common/axe-core/axe-core';
import ChronicCareCommonUtils from '@teladoc/fe-ccm/ui/common/utilities/common-utils';
import ProtoUtils from '@teladoc/fe-ccm/ui/common/utilities/proto-utils';
import CurriculumAPI from '@teladoc/fe-ccm/ui/dashboard/home/curriculum/curriculum-api';
import CurriculumUtils from '@teladoc/fe-ccm/ui/dashboard/home/curriculum/curriculum-utils';
import NoMatch from '@teladoc/fe-ccm/ui/router/NoMatch';
import RoutePrivate from '@teladoc/fe-ccm/ui/router/RoutePrivate';
import {userUpdate} from '@teladoc/fe-ccm/ui/user/user-actions';
import UserAPI from '@teladoc/fe-ccm/ui/user/user-api';
import ScaleConfirmationModal from '@teladoc/fe-ccm/ui/weight/common/scale-confirmation-modal/ScaleConfirmationModal';
import {
    useI18nContext,
    useG11nBidirectionalStateManager,
} from '@teladoc/pulse/ui/Context/i18n';
import {
    MEDOPT_SIGNUP,
    ONEAPP_HOME_PATH,
    FEATURE_FLAG_NAMES,
    MEDOPT_WM_DPP_SIGNUP,
    ONBOARDING_INTRO_SEEN,
    SEND_MESSAGE_ONCE,
} from '@teladoc/fe-ccm/ui/config';
import notify from '@teladoc/pulse/ui/Notification';
import OnboardingAPI from '@teladoc/fe-ccm/ui/onboarding/onboarding-api';
import Arg from '@livongo/arg';
import DOMUtils from '@livongo/utilities/system/dom';
import {useFeatureFlagContext} from '@livongo/utilities/system/featureFlag';
import StorageUtils from '@livongo/utilities/system/storage';
import Footer from '@/Footer';
import Header from '@/Header';
import {
    HOME,
    IS_PROD,
    FAQ_URL,
    NAV_MENU,
    IS_NON_PROD,
    AUTHENTICATION,
    BREAKPOINT_SIZE,
    OFFERED_PROGRAMS,
    ACCESS_TOKEN_PREV,
    ACCESS_TOKEN_COOKIE,
    REFRESH_TOKEN_COOKIE,
    CONDITION_MANAGEMENT,
    FOOTER_CONTENT_STATE,
    IS_TDOC_TOKEN_INVALID,
    MESSAGE_COUNTER_EXPIRATION,
    MESSAGE_COUNTER_MINUTES_TTL,
    CARE_RECIPIENT_AGREEMENT_URL,
    NONSTANDARD_MENTAL_HEALTH_ROUTES,
    VIDEO_PATH,
} from '@/config';
import {
    navRoutes,
    mainRoutes,
    CCMSubRoute,
    getPrimaryRouteById,
    MESSAGE_CENTER_PATH,
    MENTAL_HEALTH_PREFIX,
    CONDITION_MANAGEMENT_PREFIX,
    INBOX_ROUTES,
} from '@/router/routes';
import {getStore} from '@/store';
import CommonUtils from '@/utils/common-utils';
import OneAppUserAPI from '@/user/user-oneapp-api';
import css from './Root.scss';

const SidebarComponent = lazy(() => import('@/Sidebar'));
const ChatComponent = lazy(() => import('@teladoc/fe-ccm/ui/chat/Chat'));
const MentalHealthFlyoutComponent = lazy(() =>
    import('@/Messages/mental-health/MentalHealthFlyout')
);
const SessionExpiredModalComponent = lazy(() =>
    import(
        '@teladoc/fe-ccm/ui/common/session-expired-modal/SessionExpiredModal'
    )
);
const InvalidatedSessionModalComponent = lazy(() =>
    import('@/invalidated-session-modal/InvalidatedSessionModal')
);

const Root = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {featureFlags, updateFeatureFlag} = useFeatureFlagContext();
    const screenWidth = DOMUtils.width('body');
    const {pathname, hash, host} = document.location;
    const previousPath = document.referrer;
    const locationWithHash = `${pathname}${hash}`;
    const hostName = host?.split('.');
    const pathNameWTOSlash = pathname?.split('/');
    const {changeLanguage} = useI18nContext();
    const {
        branding,
        isOneApp,
        breakpoint,
        isTokenValid,
        isOneAppSidebar,
        hasChatAvailable,
        oneAppActiveProgram,
        weightScaleConfirmation,
    } = useSelector(state => state.app);

    const {activePrograms, isAuthenticated, userLocale} = useSelector(
        state => state.user
    );
    const {isShowing: isChatShowing, channels} = useSelector(
        state => state.chat
    );
    const [headerTitle, setHeaderTitle] = useState('');
    const [showHamburger, setShowHamburger] = useState(false);
    const [isMobileMenuPage, setIsMobileMenuPage] = useState(false);
    const [showMHGuide, setShowMHGuide] = useState(false);
    const [hideMobileTopHeader, setHideMobileTopHeader] = useState(false);
    const [showIdleModal, setShowIdleModal] = useState(false);
    const [onboardingNotify, setOnboardingNotify] = useState(false);
    const [showInvalidSessionModal, setShowInvalidSessionModal] =
        useState(false);
    const [triggerUnreadCounter, setTriggerUnreadCounter] = useState(false);
    const [isMentalHealthWebsdkEnabled, setIsMentalHealthWebsdkEnabled] =
        useState(
            ChronicCareCommonUtils.isFeatureEnabled(
                featureFlags,
                FEATURE_FLAG_NAMES.mhWebsdk,
                true
            )
        );
    const [enableGlpEducation, setEnableGlpEducation] = useState(
        CommonUtils.isFeatureEnabled(
            featureFlags,
            FEATURE_FLAG_NAMES.enableGlpEducation,
            isOneApp
        )
    ); // Todo: must remove this flag
    const conditionManagementHomePath =
        getPrimaryRouteById(CONDITION_MANAGEMENT).path;
    const isBloodPressureRetakeLink =
        window.location.href.includes('/l/followup');
    const redirectToEducation = Arg('redirectToEducation');
    const relativeRoutes = useRef([
        ...mainRoutes,
        ...navRoutes,
        ...CCMSubRoute,
    ]);

    const onSideNavToggle = evt => {
        const bodyElm = document.querySelector('body');

        if (!isOneAppSidebar) {
            bodyElm?.classList?.add('Sidebar-showing');
        } else {
            bodyElm?.classList?.remove('body', 'Sidebar-showing');
        }

        CommonUtils.updateSidebar(!isOneAppSidebar, dispatch);
    };

    const onScaleModalToggle = () => {
        dispatch(
            appUpdate({
                weightScaleConfirmation: !weightScaleConfirmation,
            })
        );
    };

    const onChatToggle = () => {
        dispatch(chatSetCurrentChannel(null));
        dispatch(chatToggle());
    };

    const initialLoadEvents = () => {
        const pageName =
            pathNameWTOSlash[2] || pathNameWTOSlash[1] || NAV_MENU.Home.id;

        mixpanel.track(`${pageName}.load.completed`);
    };

    const updateMobileWebHeaderTitle = id => {
        let title = camelCase(id);

        switch (id) {
            case 'condition-profile':
                title = 'healthProfile';
                break;
            case 'programs':
                title = 'availablePrograms';
                break;
            case 'reports-and-data':
                title = 'oneAppReportsAndData';
                break;
            case 'user-guides':
                title = 'oneAppUserGuides';
                break;
        }

        return title;
    };

    const isMentalHealthHeaderPath = useCallback(() => {
        return (
            pathname?.includes(MENTAL_HEALTH_PREFIX) &&
            !pathname?.includes(
                NONSTANDARD_MENTAL_HEALTH_ROUTES.Interstitial
            ) &&
            !pathname?.includes(NONSTANDARD_MENTAL_HEALTH_ROUTES.Onboarding) &&
            !pathname?.includes(
                NONSTANDARD_MENTAL_HEALTH_ROUTES.Reassessment
            ) &&
            !pathname?.includes(NONSTANDARD_MENTAL_HEALTH_ROUTES.Login) &&
            !pathname?.includes(MESSAGE_CENTER_PATH)
        );
    }, [pathname]);

    const isMentalHealthSidebarPath = useCallback(() => {
        return (
            isMentalHealthHeaderPath() &&
            !locationWithHash?.includes(
                NONSTANDARD_MENTAL_HEALTH_ROUTES.WaitingRoomHash
            )
        );
    }, [isMentalHealthHeaderPath, locationWithHash]);

    const isMentalHealthNonSidebarPath = useCallback(() => {
        return (
            pathname?.includes(MENTAL_HEALTH_PREFIX) &&
            !isMentalHealthSidebarPath()
        );
    }, [isMentalHealthSidebarPath, pathname]);

    /* eslint-disable camelcase */
    const screenHeadersObject = {
        ProgramsScreen: t(`sidebar.navigation.mentalHealthSleepProgram`),
        add_entry: t(`sidebar.navigation.mentalHealthSleepAddEntry`),
        completedSleepEntry: t(`sidebar.navigation.mentalHealthSleepAddEntry`),
        create_schedule: t(
            `sidebar.navigation.mentalHealthSleepCreateSchedule`
        ),
        weekly_checkin: t(`sidebar.navigation.mentalHealthSleepWeeklyCheckin`),
        edit_schedule: t(`sidebar.navigation.mentalHealthSleepEditSchedule`),
        week_entries: t(`sidebar.navigation.mentalHealthSleepWeekEntries`),
    };
    /* eslint-enable camelcase */

    const screenSpecificHeaderTitle =
        CommonUtils.getHeaderTitleForMatchingScreen(hash, screenHeadersObject);

    const enableTitleAndHamburger = useCallback(() => {
        const mhPath3 = isMentalHealthHeaderPath() ? pathNameWTOSlash[3] : null;
        const id =
            mhPath3 ||
            pathNameWTOSlash[2] ||
            pathNameWTOSlash[1] ||
            'condition-management';

        if (setShowHamburger && setHeaderTitle) {
            setShowHamburger(true);
            setHeaderTitle(
                t(`sidebar.navigation.${updateMobileWebHeaderTitle(id)}`)
            );

            if (
                id === 'home' &&
                pathNameWTOSlash[1] === 'condition-management'
            ) {
                setHeaderTitle(t(`sidebar.title.conditionManagement`));
            } else if (screenSpecificHeaderTitle) {
                return setHeaderTitle(screenSpecificHeaderTitle);
            } else if (isMentalHealthHeaderPath()) {
                setHeaderTitle(
                    t(
                        `sidebar.navigation.mentalHealth${startCase(
                            id
                        )?.replaceAll(' ', '')}`
                    )
                );
            } else {
                setHeaderTitle(
                    t(`sidebar.navigation.${updateMobileWebHeaderTitle(id)}`)
                );
            }
        }
    }, [
        t,
        pathNameWTOSlash,
        isMentalHealthHeaderPath,
        screenSpecificHeaderTitle,
    ]);

    const isValidSidebarPath = useCallback(() => {
        const documentLocation = `${document.location.pathname}${document.location.hash}`;
        let validSidebarPath = documentLocation?.includes(
            CONDITION_MANAGEMENT_PREFIX
        );

        if (
            ChronicCareCommonUtils.isFeatureEnabled(
                featureFlags,
                FEATURE_FLAG_NAMES.mhWebsdk,
                isOneApp
            )
        ) {
            validSidebarPath =
                validSidebarPath || isMentalHealthSidebarPath(documentLocation);
        }

        return validSidebarPath;
    }, [featureFlags, isOneApp, isMentalHealthSidebarPath]);

    const updateSidebarState = useCallback(() => {
        if (breakpoint !== BREAKPOINT_SIZE.Small && isValidSidebarPath()) {
            CommonUtils.updateSidebar(true, dispatch);
        } else {
            CommonUtils.updateSidebar(false, dispatch);
        }
    }, [breakpoint, isValidSidebarPath, dispatch]);

    const resetSideNavBar = evt => {
        if (!evt.executed) {
            updateSidebarState();
            evt.executed = true;
        }
    };

    const updateUnreadCounter = response => {
        if (response?.unread_messages_count) {
            const {telemed, mental_health: myStrength} =
                response.unread_messages_count;

            const channelMsgInfo = channels[0];
            const foodLoggerInfo = channels[1];

            dispatch(
                chatUpdateUnreadCount({
                    hasUnread: {
                        telemed: telemed > 0,
                        myStrength: myStrength > 0,
                        chronicCare: channelMsgInfo?.unreadMessageCount > 0,
                        foodLogger: foodLoggerInfo?.unreadMessageCount > 0,
                    },
                })
            );

            const setCounterExpirationTime = addMinutes(
                new Date(),
                MESSAGE_COUNTER_MINUTES_TTL
            );

            CommonUtils.setLocalStorage({
                key: MESSAGE_COUNTER_EXPIRATION,
                value: setCounterExpirationTime,
            });
        }
    };

    /* start: events triggered on Initial App Load */
    const triggerMessageEndpoint =
        CommonUtils.validateRedirectURL(locationWithHash) ||
        !isBefore(
            new Date(),
            new Date(CommonUtils.getLocalStorage(MESSAGE_COUNTER_EXPIRATION))
        ) ||
        false;

    useEffect(() => {
        if (OneAppUserAPI.getTdocAuthToken()) {
            OneAppUserAPI.getMemberNavigation()
                .then(response => {
                    updateUnreadCounter(response);

                    if (isNil(response?.navigation)) {
                        return;
                    }
                    // sample JSON response for member_navigation API
                    // member_navigation : {"navigation":{"Condition Management": ["DIABETES", "HYPERTENSION"],"Mental Health Support": ["DIGITAL","COMPLETE"],
                    //   "Telemed": ["Primary Care team","Primary Care plan","Expert Medical Opinion cases", "Primary Care vitals"]},
                    //   "branding":{"brand_code": "teladoc", "brand_name": "Teladoc", "bundle_identifier": "com.teladoc",
                    //   "consent_agreement_url": {"en-us": "https://goto.integration.livongo.com/web-privacy-policy?locale=en-US",
                    //   "es-us": "https://goto.integration.livongo.com/web-privacy-policy?locale=es-US"}, "logo_path": "registration/common/Teladoc_Logo_ES.svg",
                    //   "favicon_url": "https://www.teladoc.com/wp-content/themes/teladoc-members/media/images/icon.gif", "non_discrimination_url": {
                    //   "en-us": "https://goto.integration.livongo.com/notice-of-non-discrimination?locale=en-US",
                    //   "es-us": "https://goto.integration.livongo.com/notice-of-non-discrimination?locale=es-US"}, "organization": "Teladoc Health, Inc.",
                    //   "terms_of_service_url": {"en-us": "https://goto.integration.livongo.com/terms-of-service?locale=en-US",
                    //   "es-us": "https://goto.integration.livongo.com/terms-of-service?locale=es-US"}, "video_url": "https://member.teladoc.com/test_video"}}
                    dispatch(
                        appUpdate({
                            hasCareTeam: response?.telemed_inbox,
                            branding: response?.branding,
                            hasTelemed: {
                                primaryCarePlan:
                                    response?.navigation?.Telemed?.includes(
                                        OFFERED_PROGRAMS.PrimaryCarePlan
                                    ) || false,
                                primaryCareTeam:
                                    response?.navigation?.Telemed?.includes(
                                        OFFERED_PROGRAMS.PrimaryCareTeam
                                    ) || false,
                                expertOpinionCases:
                                    response?.navigation?.Telemed?.includes(
                                        OFFERED_PROGRAMS.ExpertMedicalOpinion
                                    ) || false,
                                primaryCareVitals:
                                    response?.navigation?.Telemed?.includes(
                                        OFFERED_PROGRAMS.PrimaryCareVitals
                                    ) || false,
                            },
                            hasConditionManagement: Boolean(
                                response?.navigation[
                                    OFFERED_PROGRAMS.ConditionManagement
                                ]
                            ),
                            hasMentalHealth: Boolean(
                                response?.navigation[
                                    OFFERED_PROGRAMS.MentalHealth
                                ]
                            ),
                        })
                    );

                    StorageUtils.remove({
                        key: IS_TDOC_TOKEN_INVALID,
                    });
                })
                .catch(exception => {
                    if (
                        exception
                            ?.toString()
                            ?.toLowerCase()
                            ?.includes(AUTHENTICATION)
                    ) {
                        StorageUtils.set({
                            key: IS_TDOC_TOKEN_INVALID,
                            value: true,
                        });
                        setShowInvalidSessionModal(true);
                    }
                });
            dispatch(
                userUpdate({userLocale: CommonUtils.getOneAppLocaleCookie()})
            );
            ProtoUtils.setHeaders({
                'Accept-Language': CommonUtils.getOneAppLocaleCookie(),
            });
            changeLanguage(CommonUtils.getOneAppLocaleCookie());
            ApptentiveUtils.event('oneapp.loaded');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            (triggerMessageEndpoint || triggerUnreadCounter) &&
            OneAppUserAPI.getTdocAuthToken() &&
            CommonUtils.getLocalStorage(MESSAGE_COUNTER_EXPIRATION)
        ) {
            setTriggerUnreadCounter(false);
            OneAppUserAPI.getMemberNavigation()
                .then(response => {
                    updateUnreadCounter(response);

                    StorageUtils.remove({
                        key: IS_TDOC_TOKEN_INVALID,
                    });
                })
                .catch(exception => {
                    if (
                        exception
                            ?.toString()
                            ?.toLowerCase()
                            ?.includes(AUTHENTICATION)
                    ) {
                        StorageUtils.set({
                            key: IS_TDOC_TOKEN_INVALID,
                            value: true,
                        });
                        setShowInvalidSessionModal(true);
                    }
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerMessageEndpoint, triggerUnreadCounter]);
    /* end: events triggered on Initial App Load */

    useEffect(() => {
        const coachingModalButton = document.querySelector(
            'button[name="mh_text_coaching_modal_button"]'
        );

        if (coachingModalButton) {
            const handleClick = () => {
                setShowMHGuide(true);
            };

            coachingModalButton.addEventListener('click', handleClick);
        }
    });

    // event to Handle Browser Back & Forward button click
    window.addEventListener('popstate', resetSideNavBar);

    // To Handle The Resizing event of browser
    useEffect(() => {
        updateSidebarState();
    }, [breakpoint, dispatch, pathname, updateSidebarState]);

    // TODO: Not sure if this can be combined with the above. Needs more analysis
    useEffect(() => {
        if (isValidSidebarPath()) {
            if (breakpoint === BREAKPOINT_SIZE.Small) {
                enableTitleAndHamburger();
            } else if (!isOneAppSidebar) {
                CommonUtils.updateSidebar(true, dispatch);
            }
        }
    }, [
        dispatch,
        breakpoint,
        isOneAppSidebar,
        isValidSidebarPath,
        enableTitleAndHamburger,
        weightScaleConfirmation,
    ]);

    window.addEventListener(
        'resize',
        debounce(() => CommonUtils.setBreakpoint(breakpoint, dispatch), 500)
    );

    const onStayClick = () => {
        setShowIdleModal(false);
    };

    const onIdle = () => {
        if (pathname?.includes(VIDEO_PATH)) return;

        // only show session expiration modal after 14 minutes of idle time if not in development
        if (process.env.ENVIRONMENT !== 'local') {
            setShowIdleModal(true);
        }
    };

    const onIdleWTOMessageCounter = e => {
        if (pathname?.includes(VIDEO_PATH)) return;

        if (process.env.ENVIRONMENT !== 'local') {
            setTriggerUnreadCounter(true);
        }
    };

    // This is used to refresh the user's education session when it times out due to inactivity
    const refreshEducationSession = useCallback(async () => {
        const loadedUrl = await CurriculumAPI.getUrl({
            enableGlpEducation,
            activePrograms,
        });

        const surfacedProgram =
            CurriculumUtils.selectProgramToSurface(activePrograms);

        const urlWithParams = CurriculumUtils.applyUrlParams({
            url: loadedUrl,
            surfacedProgram,
            isOneApp: true,
            enableGlpEducation,
        });

        window.open(urlWithParams, '_self');
    }, [enableGlpEducation, activePrograms]);

    useEffect(() => {
        if (redirectToEducation) {
            refreshEducationSession();
        }
    }, [redirectToEducation, refreshEducationSession]);

    if (!OneAppUserAPI.getTdocAuthToken()) {
        OneAppUserAPI.telemedLogout(branding?.member_host);
    }

    useIdleTimer({
        element: document,
        debounce: 1000,
        timeout: 840000, // 14 minutes
        onIdle,
    });

    useIdleTimer({
        element: document,
        debounce: 1000,
        timeout: 540000, // 9 minutes
        onIdle: onIdleWTOMessageCounter,
    });

    // TODO: Temporary fix for 1/1
    if (pathname.includes('help/faq')) {
        window.open(FAQ_URL, '_blank');

        window.history.pushState(
            {},
            '',
            getPrimaryRouteById(NAV_MENU.Home.id).path
        );
    }

    if (pathname.includes('terms/cr_agreement')) {
        window.open(CARE_RECIPIENT_AGREEMENT_URL, '_blank');
    }

    if (pathname.includes('my_family/overview')) {
        window.history.pushState({}, '', '/account/family');
    }

    // TODO: Refactor this logic as part of TIME-790
    if (pathname?.includes('communication-preferences') && showHamburger) {
        setShowHamburger(false);
    }

    useG11nBidirectionalStateManager(
        userLocale,
        useCallback(
            code => {
                dispatch(userUpdate({userLocale: code}));
            },
            [dispatch]
        )
    );

    // Redirect members to the medopt-signup page
    useEffect(() => {
        // e.g. teladoc.com/medopt-signup
        const hasMedOptOption =
            pathname.includes(MEDOPT_SIGNUP) ||
            pathname.includes(MEDOPT_WM_DPP_SIGNUP);

        if (
            hasMedOptOption &&
            !isNil(OneAppUserAPI.getCurrentCcmAccessToken())
        ) {
            document.writeln(t(`redirecting.goTo`));

            const oneAppParams =
                CommonUtils.generateOneAppParams(ONEAPP_HOME_PATH);

            window.location.href = `${UserAPI.getSignupUrl({
                signupUrl: process.env.SIGNUP_URL,
                locale: userLocale,
                medOptFlavor: pathname.includes(MEDOPT_WM_DPP_SIGNUP),
            })}${oneAppParams}`;
        }
    }, [pathname, t, userLocale]);

    useEffect(() => {
        if (isMentalHealthNonSidebarPath(pathname)) {
            setHideMobileTopHeader(true);
            setShowHamburger(false);
            setHeaderTitle('');
        } else if (
            pathname?.includes(CONDITION_MANAGEMENT_PREFIX) &&
            isEmpty(OneAppUserAPI.getCurrentCcmAccessToken())
        ) {
            window.location.href = `https://${hostName.join('.')}/ccm-sso`;
        } else {
            setHideMobileTopHeader(false);
        }
    }, [pathname, hostName, isMentalHealthNonSidebarPath]);

    useEffect(() => {
        getStore();
        initialLoadEvents();
        dispatch(
            appUpdate({
                isOneApp: true,
                breakpoint:
                    screenWidth <= '767'
                        ? BREAKPOINT_SIZE.Small
                        : BREAKPOINT_SIZE.Large,
            })
        );

        OneAppUserAPI.hasUserMHGuideAccess().then(hasGuide => {
            dispatch(appUpdate({hasMHGuideAvailable: hasGuide}));
        });

        dispatch(appUpdate({oneAppActiveNav: NAV_MENU.Home.id}));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        // re-dispatch chatConnect to make sure that we're sending along the correct, updated language to Sendbird
        if (hasChatAvailable) {
            dispatch(chatConnect());
        }
    }, [dispatch, hasChatAvailable]);

    useEffect(() => {
        if (isBloodPressureRetakeLink) {
            dispatch(appUpdate({isBloodPressureRetakeFromLink: true}));
        }
    }, [dispatch, isBloodPressureRetakeLink]);

    useEffect(() => {
        setIsMentalHealthWebsdkEnabled(
            ChronicCareCommonUtils.isFeatureEnabled(
                featureFlags,
                FEATURE_FLAG_NAMES.mhWebsdk,
                true
            )
        );
        setEnableGlpEducation(
            ChronicCareCommonUtils.isFeatureEnabled(
                featureFlags,
                FEATURE_FLAG_NAMES.enableGlpEducation,
                isOneApp
            )
        );
    }, [isOneApp, featureFlags]);

    // The point of this is to change which routes will be available to the app based on a feature flag
    // The condition looks a little messy, but what it does is this:
    // If the route doesn't have any feature flag props, include it in our routes
    // If the route specifically says a feature flag will enable it, keep it if the feature flag is enabled but remove it otherwise
    // If the route specifically says a feature flag will disable it, remove it if the feature flag is enabled but keep it otherwise
    useEffect(() => {
        relativeRoutes.current = [
            ...mainRoutes,
            ...navRoutes,
            ...CCMSubRoute,
        ].filter(
            route =>
                (!route.featureFlagEnable && !route.featureFlagDisable) ||
                (isMentalHealthWebsdkEnabled
                    ? route.featureFlagEnable === 'mhWebsdk'
                    : route.featureFlagDisable === 'mhWebsdk')
        );
    }, [isMentalHealthWebsdkEnabled]);

    // Add Apptentive pageload event only to route with Apptentive event.
    useEffect(() => {
        const loadRoute = relativeRoutes?.current?.find(
            route => route.path === pathname
        );

        if (loadRoute?.apptentiveEvent && loadRoute?.id) {
            ApptentiveUtils.event(
                `navigation.page.${loadRoute.id?.toLowerCase()}.loaded`
            );
        }
    }, [pathname]);

    // Intro to Onboarding with Notification Modal
    // Only when we're coming from OneApp Dashboard/GetCare and landing on Condition Management
    useEffect(() => {
        const previouslyFromGetCareDashboard =
            CommonUtils.isOneAppDashboard(previousPath);

        const currentPathCCM = pathname?.includes(CONDITION_MANAGEMENT_PREFIX);

        const onExploreProgram = () => {
            dispatch(
                appUpdate({
                    hasOnboardingPrograms: true,
                })
            );
        };

        // we're coming from OneApp Dashboard/GetCare and landing on Condition Management
        if (previouslyFromGetCareDashboard && currentPathCCM) {
            const isDashboardOfCCM = pathNameWTOSlash?.includes(HOME);

            const hasSeenOnboarding = StorageUtils.get({
                key: ONBOARDING_INTRO_SEEN,
                type: 'session',
            });

            // Only when it's not in Condition Management Dashboard and has not shown Onboarding flow
            // and has not shown the Notification
            if (!isDashboardOfCCM && !hasSeenOnboarding && !onboardingNotify) {
                // Set Notified
                setOnboardingNotify(true);

                OnboardingAPI.display().then(res => {
                    const isOnboardingEnabled =
                        ChronicCareCommonUtils.isFeatureEnabled(
                            featureFlags,
                            FEATURE_FLAG_NAMES.onboarding,
                            true
                        );

                    if (res?.data?.showOneAppCard && isOnboardingEnabled) {
                        notify({
                            type: 'info',
                            autoClose: 10000, // auto close in 10 second
                            title: t(`onboarding.homeCard.heading`),
                            message: t(`onboarding.homeCard.description`),
                            buttonText: t(`onboarding.homeCard.action`),
                            onClick: () => {
                                onExploreProgram();
                            },
                        });
                    }
                });
            }
        }
    }, [
        t,
        dispatch,
        pathname,
        featureFlags,
        previousPath,
        pathNameWTOSlash,
        onboardingNotify,
    ]);

    useEffect(() => {
        if (isTokenValid) {
            CommonUtils.removeLocalStorage(ACCESS_TOKEN_PREV);
            CommonUtils.removeCookie(ACCESS_TOKEN_COOKIE);
            CommonUtils.removeCookie(REFRESH_TOKEN_COOKIE);
            OneAppUserAPI.refreshToken();
        }
    }, [isTokenValid]);

    useEffect(() => {
        if (
            isOneApp &&
            !pathname.includes(INBOX_ROUTES.chronicCare) &&
            !isEmpty(CommonUtils.getLocalStorage(SEND_MESSAGE_ONCE))
        ) {
            CommonUtils.removeLocalStorage(SEND_MESSAGE_ONCE);
        }
    }, [pathname, isOneApp]);

    /**
     * Initializing @axe-core/react
     * https://github.com/dequelabs/axe-core-npm/blob/develop/packages/react/README.md
     */
    ReactAxeCore({loadScript: IS_NON_PROD});

    // This window object is used to update FeatureFlag Toggle via console.
    if (!IS_PROD) {
        window.updateFeatureFlag = ({
            featureName,
            oneAppActive = false,
            legacyActive = false,
        }) => {
            return updateFeatureFlag({
                featureName,
                oneAppActive,
                legacyActive,
            });
        };
    }

    return (
        <Router>
            {isAuthenticated && (
                <>
                    <Header
                        headerTitle={headerTitle}
                        hideMobileTopHeader={hideMobileTopHeader}
                        showHamburger={showHamburger}
                        setHeaderTitle={setHeaderTitle}
                        setShowMHGuide={setShowMHGuide}
                        onSideNavToggle={onSideNavToggle}
                        setShowHamburger={setShowHamburger}
                        setIsMobileMenuPage={setIsMobileMenuPage}
                        setHideMobileTopHeader={setHideMobileTopHeader}
                    />
                    <main
                        id="mainContent"
                        className={cnb(css.main, {
                            [css.showSidebar]: isOneAppSidebar,
                            [css.mobileNavBGColor]: isMobileMenuPage,
                            [css.mobileMentalHealth]: hideMobileTopHeader,
                            [css.websdkContainer]:
                                oneAppActiveProgram ===
                                FOOTER_CONTENT_STATE.TeleMed.id,
                        })}
                    >
                        <div id="toast-portal" />
                        {isOneAppSidebar && (
                            <SidebarComponent
                                isOpen={showHamburger}
                                setHeaderTitle={setHeaderTitle}
                                onSideNavToggle={
                                    showHamburger &&
                                    breakpoint === BREAKPOINT_SIZE.Small
                                        ? onSideNavToggle
                                        : null
                                }
                            />
                        )}
                        <div
                            className={cnb(css.content, {
                                [css.hideMobileContent]: isOneAppSidebar,
                            })}
                        >
                            <Suspense fallback={<Loader />}>
                                <Switch>
                                    <Route
                                        exact
                                        path="/condition-management/l/followup"
                                    >
                                        <Redirect
                                            to={conditionManagementHomePath}
                                        />
                                    </Route>
                                    <Route exact path="/condition-management">
                                        <Redirect
                                            to={conditionManagementHomePath}
                                        />
                                    </Route>
                                    {relativeRoutes.current.map(
                                        ({
                                            id,
                                            path,
                                            exact,
                                            programSlugs,
                                            component: Component,
                                            subNavRoutes,
                                        }) => {
                                            const props = {
                                                id,
                                                path,
                                                isOneApp: true,
                                                component: Component,
                                                subNavRoutes,
                                                setHeaderTitle,
                                                setShowMHGuide,
                                                setShowHamburger,
                                                setIsMobileMenuPage,
                                                programSlugs,
                                                getOneAppRouteById:
                                                    getPrimaryRouteById,
                                            };

                                            return (
                                                <RoutePrivate
                                                    key={id}
                                                    {...props}
                                                    {...(exact && {exact})}
                                                />
                                            );
                                        }
                                    )}
                                    <Route>
                                        <NoMatch
                                            setShowSidebar={() =>
                                                CommonUtils.updateSidebar(
                                                    false,
                                                    dispatch
                                                )
                                            }
                                            getCareID={getPrimaryRouteById(
                                                NAV_MENU.Home.id
                                            )}
                                        />
                                    </Route>
                                </Switch>
                            </Suspense>
                        </div>
                        {isChatShowing && (
                            <ChatComponent onClose={onChatToggle} />
                        )}
                        {showMHGuide && (
                            <MentalHealthFlyoutComponent
                                showMHGuide={showMHGuide}
                                setShowMHGuide={setShowMHGuide}
                                isMentalHealthWebsdkEnabled={
                                    isMentalHealthWebsdkEnabled
                                }
                            />
                        )}
                        {showIdleModal && (
                            <SessionExpiredModalComponent
                                oneAppLogout={OneAppUserAPI.logout}
                                isOpen={showIdleModal}
                                onStay={onStayClick}
                                setShowIdleModal={setShowIdleModal}
                            />
                        )}
                        {showInvalidSessionModal && (
                            <InvalidatedSessionModalComponent
                                oneAppLogout={OneAppUserAPI.logout}
                                isOpen={showInvalidSessionModal}
                            />
                        )}
                    </main>
                    <Footer />
                    <ScaleConfirmationModal
                        isOpen={weightScaleConfirmation}
                        onRequestClose={onScaleModalToggle}
                    />
                </>
            )}
        </Router>
    );
};

export default Root;
