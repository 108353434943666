import ChronicCareCommonUtils from '@teladoc/fe-ccm/ui/common/utilities/common-utils';
import {FEATURE_FLAG_NAMES} from '@teladoc/fe-ccm/ui/config';
import {useEffect, useState} from 'react';
import {useFeatureFlagContext} from '@livongo/utilities/system/featureFlag';
import MentalHealthChat from '@/Messages/mental-health-chat/MentalHealthChat';
import css from './MentalHealth.scss';

const MentalHealth = () => {
    const {featureFlags} = useFeatureFlagContext();
    const [isMentalHealthWebsdkEnabled, setIsMentalHealthWebsdkEnabled] =
        useState(
            ChronicCareCommonUtils.isFeatureEnabled(
                featureFlags,
                FEATURE_FLAG_NAMES.mhWebsdk,
                true
            )
        );

    useEffect(() => {
        const isMhEnabled = ChronicCareCommonUtils.isFeatureEnabled(
            featureFlags,
            FEATURE_FLAG_NAMES.mhWebsdk,
            true
        );

        setIsMentalHealthWebsdkEnabled(isMhEnabled);
    }, [featureFlags]);

    if (isMentalHealthWebsdkEnabled) {
        return (
            <div className={css.MentalHealthWebSdk}>
                <MentalHealthChat
                    isMentalHealthWebsdkEnabled={isMentalHealthWebsdkEnabled}
                />
            </div>
        );
    }

    return (
        <MentalHealthChat
            isMentalHealthWebsdkEnabled={isMentalHealthWebsdkEnabled}
            frameStyles={css.MentalHealth}
        />
    );
};

export default MentalHealth;
