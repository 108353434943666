import {cnb} from 'cnbuilder';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux';
import mixpanel from 'mixpanel-browser';
import {appUpdate} from '@teladoc/fe-ccm/ui/app/app-actions';
import {
    PRIVACY_PRACTICES_URL,
    TERMS_OF_SERVICE_URL,
    NON_DISCRIMINATION_URL,
} from '@teladoc/fe-ccm/ui/config';
import IconVideoDefault from '@teladoc/pulse/icons/video-default.svg';
import Link from '@teladoc/fe-ccm/ui/router/Link';
import {getCommonRouteById} from '@/router/routes';
import {
    ACCESSIBILITY_URL,
    COUNTRY_CODES,
    CONSENT_AGREEMENT_URL,
    FOOTER_CONTENT_STATE,
    LEGAL_DISCLAIMER_URL,
    NAV_MENU,
    PRIVACY_POLICY_URL,
    VIDEO_URL,
} from '@/config';
import CommonUtils from '../utils/common-utils';
import css from './Footer.scss';

const Footer = () => {
    const {oneAppActiveProgram, branding} = useSelector(state => state.app);
    const {userLocale} = useSelector(state => state.user);
    const {t} = useTranslation();
    const country = CommonUtils.getCountry(userLocale);
    const activeStatePath = document.location.pathname;
    const dispatch = useDispatch();

    useEffect(() => {
        if (activeStatePath?.includes('condition-management')) {
            dispatch(
                appUpdate({
                    oneAppActiveProgram:
                        FOOTER_CONTENT_STATE.ConditionManagement.id,
                })
            );
        } else if (activeStatePath?.includes('mental-health')) {
            dispatch(
                appUpdate({
                    oneAppActiveProgram: FOOTER_CONTENT_STATE.MyStrength.id,
                })
            );
        } else {
            dispatch(
                appUpdate({
                    oneAppActiveProgram: FOOTER_CONTENT_STATE.TeleMed.id,
                })
            );
        }
    }, [activeStatePath, oneAppActiveProgram, dispatch]);

    const onClickHandler = property => {
        mixpanel.track('footer.button.clicked', {
            button: property,
        });
    };

    return (
        <footer className={css.root} data-test="footer-nav-menu">
            <div className={cnb(css.container, css.privateContainer)}>
                <div className={css.footerTitle}>
                    <div className={css.company}>
                        &copy; {branding?.organization}
                    </div>
                    <div className={css.bullet}>&bull;</div>
                    <div className={css.version}>
                        {t('footer.version')} {process.env.VERSION}
                    </div>
                </div>
                {oneAppActiveProgram === FOOTER_CONTENT_STATE.MyStrength.id && (
                    <div className={css.contentMyS}>
                        <p>
                            {t('footer.myStrengthContent')}
                            <a
                                className={css.contact}
                                href="tel:988"
                                aria-label={t('footer.firstPhoneNumber')}
                                onClick={() => onClickHandler('988')}
                            >
                                988
                            </a>
                            &nbsp;
                            {t('footer.or')}
                            &nbsp;
                            <a
                                className={css.contact}
                                href="tel:800-273-8255"
                                aria-label={t('footer.secondPhoneNumber')}
                                onClick={() => onClickHandler('800-273-8255')}
                            >
                                800-273-8255.
                            </a>
                        </p>
                    </div>
                )}
                <div>
                    <p className={css.links}>
                        {country === COUNTRY_CODES.CANADA ? (
                            <>
                                <a
                                    href={LEGAL_DISCLAIMER_URL[userLocale]}
                                    className={css.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={() =>
                                        onClickHandler('Legal disclaimer')
                                    }
                                    aria-label={`
                        ${t('footer.legal')}, 
                        ${t('screenReader.externalWebPage')}`}
                                >
                                    {t('footer.legal')}
                                </a>
                                <a
                                    href={PRIVACY_POLICY_URL[userLocale]}
                                    className={css.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={() =>
                                        onClickHandler(
                                            'Web and Mobile Privacy Policy'
                                        )
                                    }
                                    aria-label={`
                        ${t('footer.webAndMobilePrivacy')}, ${t(
                                        'screenReader.externalWebPage'
                                    )}`}
                                >
                                    {t('footer.webAndMobilePrivacy')}
                                </a>
                                <a
                                    href={ACCESSIBILITY_URL[userLocale]}
                                    className={css.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={() =>
                                        onClickHandler('Accessibility')
                                    }
                                    aria-label={`
                        ${t('footer.accessibility')}, ${t(
                                        'screenReader.externalWebPage'
                                    )}`}
                                >
                                    {t('footer.accessibility')}
                                </a>
                            </>
                        ) : (
                            <>
                                {oneAppActiveProgram ===
                                    FOOTER_CONTENT_STATE.TeleMed.id && (
                                    <>
                                        <a
                                            href={VIDEO_URL}
                                            className={css.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            onClick={() =>
                                                onClickHandler(
                                                    'Test Video Capabilities'
                                                )
                                            }
                                            aria-label={`
                                    ${t('footer.videoCapabilities')},  
                                    ${t('screenReader.externalWebPage')}`}
                                        >
                                            <IconVideoDefault />
                                            {t('footer.videoCapabilities')}
                                        </a>
                                        <a
                                            href={CONSENT_AGREEMENT_URL}
                                            className={css.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            onClick={() =>
                                                onClickHandler(
                                                    'Informed Consent Agreement'
                                                )
                                            }
                                            aria-label={`
                                    ${t('footer.consentAgreement')},  
                                    ${t('screenReader.externalWebPage')}`}
                                        >
                                            {t('footer.consentAgreement')}
                                        </a>
                                    </>
                                )}
                                <a
                                    href={PRIVACY_PRACTICES_URL[userLocale]}
                                    className={css.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={() =>
                                        onClickHandler(
                                            'Notice of Privacy Practices'
                                        )
                                    }
                                    aria-label={`
                            ${t('footer.privacyPractices')}, 
                            ${t('screenReader.externalWebPage')}`}
                                >
                                    {t('footer.privacyPractices')}
                                </a>
                                <a
                                    href={TERMS_OF_SERVICE_URL[userLocale]}
                                    className={css.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={() =>
                                        onClickHandler('Terms of Service')
                                    }
                                    aria-label={`
                            ${t('footer.terms')}, ${t(
                                        'screenReader.externalWebPage'
                                    )}`}
                                >
                                    {t('footer.terms')}
                                </a>
                                <a
                                    href={NON_DISCRIMINATION_URL[userLocale]}
                                    className={css.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={() =>
                                        onClickHandler(
                                            'Notice of Non-Discrimination and Language Assistance'
                                        )
                                    }
                                    aria-label={`
                            ${t('footer.nonDiscrimination')}, ${t(
                                        'screenReader.externalWebPage'
                                    )}`}
                                >
                                    {t('footer.nonDiscrimination')}
                                </a>
                                <Link
                                    className={css.link}
                                    to={getCommonRouteById('sitemap').path}
                                    onClick={() => {
                                        onClickHandler('Sitemap');
                                        dispatch(
                                            appUpdate({
                                                oneAppActiveNav:
                                                    NAV_MENU.Sitemap.id,
                                            })
                                        );
                                    }}
                                >
                                    {t('footer.sitemap')}
                                </Link>
                            </>
                        )}
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
