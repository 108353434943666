import {useState, useEffect, useCallback} from 'react';
import {paths, WebSDK, defaultTheme, MESSAGE_FLOWS} from '@td/websdk';
import ErrorBoundary from '@teladoc/pulse/ui/ErrorBoundary';
import {cnb} from 'cnbuilder';
import PropTypes from 'prop-types';
import {useHistory, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import StorageUtils from '@livongo/utilities/system/storage';
import {COUNTRY_CODES, NAV_MENU, IS_TDOC_TOKEN_INVALID} from '@/config';
import {getPrimaryRouteById, getWebsdkRoutes} from '@/router/routes';
import UserAPI from '@/user/user-oneapp-api';
import {getUrlParameter, getUpdatedWebsdkURL} from '@/utils/url-utils';
import CommonUtils from '@/utils/common-utils';
import {useMessageCenterRoutes} from '@/utils/use-message-center-routes';
import '@td/websdk/dist/index.css';
import css from './Websdk.scss';
import './index.css';

const websdkClassValue = 'flex flex-col w-full p-4 mx-auto my-4 bg-white';

const Container = ({pageName, children}) => (
    <div
        className={cnb(css.websdkContainer, websdkClassValue, {
            [css.accountScreens]: pageName === 'account',
            [css.websdkScreens]: pageName === 'websdk',
            [css.ravScreens]: pageName === 'rav',
            [css.messageCenter]: pageName === 'messageCenter',
            [css.mhOnboardingScreens]: pageName === 'mhOnboarding',
            [css.defaultWidth]: pageName === 'default',
        })}
    >
        {children}
    </div>
);

Container.propTypes = {
    pageName: PropTypes.string,
    children: PropTypes.any,
};

const messagesFlowsConfig = {
    [MESSAGE_FLOWS.unifiedMemberAssessment]: {useWebSdk: false},
};

export const getTransferPageVendorId = () => {
    return (
        window.location.pathname?.includes('/vendor_management/transfer/') &&
        window.location.pathname
            .split('/vendor_management/transfer/')
            .slice(-1)[0]
    );
};

const useRemount = () => {
    const [key, setKey] = useState(0);
    const remount = useCallback(() => setKey(prevKey => prevKey + 1), []);

    return [key, remount];
};

const Websdk = ({path, inboxWebConfig}) => {
    const params = useParams();
    const dispatch = useDispatch();
    const {push} = useHistory();
    const {getActiveRoutesAsString} = useMessageCenterRoutes();
    const {isOneAppSidebar} = useSelector(state => state.app);
    const {userLocale} = useSelector(state => state.user);
    const country = CommonUtils.getCountry(userLocale);
    const formatLocale = locale => locale.slice(0, 2);
    const inboxConfig = {
        ...inboxWebConfig,
        messagesFlowsConfig: {
            ...(inboxWebConfig?.messagesFlowsConfig ?? {}),
            ...messagesFlowsConfig,
        },
        pubnubEnabled: true,
        pubnubSubscribeKey: process.env.PUBNUB_SUBSCRIBE_KEY,
        browserBaseUrl: inboxWebConfig?.browserBaseUrl || 'inbox',
        locale: formatLocale(userLocale),
        availableInboxes: getActiveRoutesAsString(),
        features: {appTitle: false},
    };
    const amplitudeConfig = {
        /**
         * When working with or testing amplitude analytics in the example app,
         * the amplitude development API key must be hardcoded.
         * Development API keys can be found on the amplitude portal,
         * check with data science team for access.
         * DO NOT use production API key, it will pollute the production data.
         */
        client: {
            apiKey: process.env.AMPLITUDE_API_KEY,
            configuration: {minIdLength: 1},
        },
    };
    const memberId =
        getUrlParameter('selected_member_id') ??
        params?.id ??
        UserAPI.getMemberId();
    const generalMessageId = parseInt(params?.generalMessageId, 10);
    const loggedInMemberId = UserAPI.getMemberId();
    const isPageReloaded = false; // TODO check with tcore team: By default isEmpty(inboxConfig) ? getIsPageReloaded() : false;
    const healthCategory =
        getUrlParameter('visit_type') || getUrlParameter('health_category');
    const fromScreen = getUrlParameter('from_screen');
    const specialtyFlavor = getUrlParameter('specialty_flavor');
    const assessments = window.location.pathname.match(
        /consult\/(\d+)\/assessment/
    );
    const hashName = window.location.hash;
    const inboxId = parseInt(params?.inboxId, 10);
    const messageId = parseInt(params?.messageId, 10);
    const notificationType = getUrlParameter('notification_type');
    const tobaccoCessation = getUrlParameter('tobacco_cessation');
    const quickUrlToken = getUrlParameter('quick_url_token');
    const reasonForVisit = getUrlParameter('reason_for_visit');
    const ravFromDashboardOverlayScreen = getUrlParameter(
        'rav_from_dashboard_overlay_screen'
    );
    const verifyIdentityToken = getUrlParameter('token');
    const dependentId = getUrlParameter('dependent_id');
    const caseId = getUrlParameter('case_id');
    const consultId =
        getUrlParameter('consult_id') ?? parseInt(params?.consultId, 10);
    const consultationId =
        getUrlParameter('consultation_id') ??
        parseInt(params?.consultationId, 10);
    const [destination, setDestination] = useState(
        getUrlParameter('destination')
    );
    const [isDeepLink, setIsDeepLink] = useState(
        getUrlParameter('is_deep_link') === 'true'
    );
    const slug = getUrlParameter('slug');
    const id = getUrlParameter('id');
    const transferPageVendorId = getTransferPageVendorId();
    const version = getUrlParameter('version');
    const referrer = getUrlParameter('referrer');
    const surveyMethodCd = getUrlParameter('survey_method_cd');
    const brandCountryCode = country;
    const vendorId = getUrlParameter('vendor_id');
    const screenParams = {};
    const [key, remount] = useRemount();
    const [webSDKConfig, updateWebSDKConfig] = useState({
        baseUrl: getUpdatedWebsdkURL(),
        apiToken: process.env.WEBSDK_MEMBER_APP_API_TOKEN,
        authToken: UserAPI.getTdocAuthToken(),
        bundleIdentifier:
            country === COUNTRY_CODES.CANADA
                ? process.env.WEBSDK_BUNDLE_IDENTIFIER_CA
                : UserAPI.getBundleIdentifier(),
        authorizedMemberId: loggedInMemberId,
        inboxConfig, // TODO check with tcore team
        amplitudeConfig,
        googleApiKey: process.env.WEBSDK_GOOGLE_API_KEY,
        showBackButton: true, // TODO: Check again
        locale: formatLocale(userLocale),
        paypalClientId: process.env.WEBSDK_PAYPAL_CLIENTID,
        paypalMerchantId: process.env.WEBSDK_PAYPAL_MERCHANT_ID,
        idSite: process.env.PIWIK_SITE_ID, // TODO: this is prod. piwik id site
        // TODO: Can probably refactor this part to be more generic; that is, to iterate through each of the
        // URL parameters, separate them into key/value pairs, and add them to the initialState object so we
        // don't have to modify this code every time a new parameter is used by a WebSDK component
        initialState: {
            ...(healthCategory && {
                dfdDashboard: true,
                // eslint-disable-next-line camelcase
                health_category: healthCategory,
            }),
            ...(fromScreen && {
                // eslint-disable-next-line camelcase
                from_screen: fromScreen,
            }),
            ...(specialtyFlavor && {
                // eslint-disable-next-line camelcase
                specialty_flavor: specialtyFlavor,
            }),
            ...(assessments && {
                // eslint-disable-next-line camelcase
                consultation_id: assessments[1],
            }),
            ...(consultId && {
                // eslint-disable-next-line camelcase
                consult_id: consultId,
            }),
            ...(consultationId && {
                // eslint-disable-next-line camelcase
                consultation_id: consultationId,
            }),
            ...(surveyMethodCd && {
                // eslint-disable-next-line camelcase
                survey_method_cd: surveyMethodCd,
            }),
            ...(tobaccoCessation && {
                // eslint-disable-next-line camelcase
                reason_for_visit: 'Tobacco Cessation',
            }),
            ...(quickUrlToken && {
                // eslint-disable-next-line camelcase
                quick_url_token: quickUrlToken,
            }),
            ...(reasonForVisit && {
                // eslint-disable-next-line camelcase
                reason_for_visit: reasonForVisit,
            }),
            ...(ravFromDashboardOverlayScreen && {
                // eslint-disable-next-line camelcase
                rav_from_dashboard_overlay_screen:
                    ravFromDashboardOverlayScreen,
            }),
            ...(verifyIdentityToken && {
                token: verifyIdentityToken,
            }),
            ...(dependentId && {
                // eslint-disable-next-line camelcase
                dependent_id: dependentId,
            }),
            ...screenParams,
            ...(caseId && {
                // eslint-disable-next-line camelcase
                case_id: caseId,
            }),
            ...(parseInt(memberId, 10) && {
                // eslint-disable-next-line camelcase
                member_id: parseInt(memberId, 10),
            }),
            ...(messageId && {
                // eslint-disable-next-line camelcase
                message_id: messageId,
            }),
            ...(generalMessageId && {
                // eslint-disable-next-line camelcase
                general_message_id: generalMessageId,
            }),
            ...(inboxId && {
                // eslint-disable-next-line camelcase
                message_room_id: inboxId,
            }),
            ...(destination && {
                // eslint-disable-next-line camelcase
                destination,
            }),
            ...(slug && {
                // eslint-disable-next-line camelcase
                slug,
            }),
            // Used with webSDK wellness content
            ...(id && {
                // eslint-disable-next-line camelcase
                id,
            }),
            // Used with webSDK wellness content
            ...(version && {
                // eslint-disable-next-line camelcase
                version,
            }),
            // Used with webSDK wellness content
            ...(referrer && {
                // eslint-disable-next-line camelcase
                referrer,
            }),
            ...(isDeepLink && {
                // eslint-disable-next-line camelcase
                is_deep_link: isDeepLink,
            }),
            ...(notificationType && {
                // eslint-disable-next-line camelcase
                notification_type: notificationType,
            }),
            ...(vendorId && {
                // eslint-disable-next-line camelcase
                vendor_id: vendorId,
            }),
            ...(transferPageVendorId && {
                // eslint-disable-next-line camelcase
                vendor_id: transferPageVendorId,
            }),
        },
        isPulse: true, // TODO check with tcore team
        isEnhancedRedesign: true, // TODO check with tcore team
        isUnifiedRegistration: false, // TODO check with tcore team
        brandCountryCode: brandCountryCode || 'USA', // TODO check with tcore team
        isOneApp: true, // Use this flag to handle styles in websdk screens
    });
    // TODO: temporary styling fix for 1/1 release.
    const [pageName, setPageName] = useState('');
    const [internalPath, setInternalPath] = useState('');
    const websdkRoutes = getWebsdkRoutes();
    const handleExit = (exitPath = '') => {
        push(exitPath || getPrimaryRouteById(NAV_MENU.Home.id).path);
    };
    const renderPageName = () => {
        const pathName = document.location.pathname;

        if (pathName?.includes('/account/')) {
            return 'account';
        } else if (
            pathName?.includes('/home') ||
            pathName?.includes('/inbox/') ||
            pathName?.includes('/connect') ||
            pathName?.includes('/explore') ||
            pathName?.includes('/progress') ||
            pathName?.includes('/personalization') ||
            pathName?.includes('/help-resources') ||
            pathName?.includes('/history') ||
            pathName?.includes('/video/') ||
            pathName?.includes('/care_team_messages')
        ) {
            return 'default';
        } else if (
            pathName?.includes('/consult/') ||
            pathName?.includes('/find_best_doctor') ||
            pathName?.includes('/expert_opinion') ||
            pathName?.includes('/information/') ||
            hashName?.includes('VendorLandingPage') ||
            pathName?.includes('/vendor_landing') ||
            pathName?.includes('/Orbittelehealth')
        ) {
            return 'rav';
        } else if (pathName?.includes('/message-center/')) {
            return 'messageCenter';
        } else if (
            pathName?.includes('/mental-health/onboarding') ||
            pathName?.includes('/mental-health/reassessment')
        ) {
            return 'mhOnboarding';
        } else {
            return 'websdk';
        }
    };

    useEffect(() => {
        const routeObj = websdkRoutes.find(route => route.path === path);

        if (routeObj) {
            setInternalPath(routeObj.websdkPath);
        }

        if (routeObj && !destination && routeObj?.destination) {
            setDestination(routeObj?.destination);
        }

        if (routeObj && !isDeepLink && routeObj?.isDeepLink) {
            setIsDeepLink(routeObj?.isDeepLink);
        }

        const renderedPageName = renderPageName();

        if (pageName !== renderedPageName) {
            setPageName(renderedPageName);
        }

        if (
            isOneAppSidebar &&
            routeObj?.websdkPath === paths.COMMUNICATION_PREFERENCES
        ) {
            CommonUtils.updateSidebar(false, dispatch);
        }

        if (routeObj?.websdkPath === paths.INBOX) {
            const elm = document.getElementsByClassName('messaging-title');

            setTimeout(() => {
                if (elm.length > 0) {
                    elm[0]?.setAttribute('tabindex', '0');
                    elm[0]?.focus();
                }
            }, 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path, websdkRoutes, isOneAppSidebar, dispatch, pageName]);

    useEffect(() => {
        if (formatLocale(userLocale) !== webSDKConfig.locale) {
            updateWebSDKConfig(config => ({
                ...config,
                locale: formatLocale(userLocale),
            }));
            // Get the WebSDK component to re-render when the language changes
            // (If anyone can figure out how to refactor this without having to do a full refresh, please do!)
            remount();
            // window.location.reload(false);
        }
    }, [userLocale, webSDKConfig.locale, remount]);

    const Remount = () => {
        remount();

        return null;
    };

    useEffect(() => {
        updateWebSDKConfig(config => ({
            ...config,
            initialState: {
                ...config.initialState,
                ...(destination && {
                    destination,
                }),
            },
        }));
    }, [destination]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        updateWebSDKConfig(config => ({
            ...config,
            initialState: {
                ...config.initialState,
                ...(isDeepLink && {
                    // eslint-disable-next-line camelcase
                    is_deep_link: isDeepLink,
                }),
            },
        }));
    }, [isDeepLink]); // eslint-disable-line react-hooks/exhaustive-deps

    if (StorageUtils.get({key: IS_TDOC_TOKEN_INVALID})) {
        return null;
    } else {
        return (
            <Container pageName={pageName} key={key}>
                <ErrorBoundary errorComponent={<Remount />}>
                    <WebSDK
                        theme={defaultTheme}
                        path={internalPath}
                        appConfiguration={{...webSDKConfig}}
                        isPageReloaded={isPageReloaded}
                        onExit={handleExit}
                    />
                </ErrorBoundary>
            </Container>
        );
    }
};

Websdk.propTypes = {
    path: PropTypes.string,
    inboxWebConfig: PropTypes.object,
};

export default Websdk;
