import PropTypes from 'prop-types';
import {getPrimaryRouteById} from '@/router/routes';
import Websdk from '@/app/Websdk';
import {
    MYSTRENGTH_GUIDE_MESSAGE_FLAG,
    MYSTRENGTH_ONEAPP_FLAG,
    INBOX_SERVICE_TYPES,
} from '@/config';

const MentalHealthChat = ({frameStyles, isMentalHealthWebsdkEnabled}) => {
    if (isMentalHealthWebsdkEnabled) {
        const path = getPrimaryRouteById(INBOX_SERVICE_TYPES.MentalHealth).path;

        return <Websdk path={path} />;
    }

    return (
        <iframe
            className={frameStyles}
            id="mentalHealthGuideFrame"
            title="Mental Health Guide Frame"
            src={`${process.env.MYSTRENGTH_URL}/guide?${MYSTRENGTH_ONEAPP_FLAG}&${MYSTRENGTH_GUIDE_MESSAGE_FLAG}`}
        />
    );
};

MentalHealthChat.propTypes = {
    frameStyles: PropTypes.object,
    isMentalHealthWebsdkEnabled: PropTypes.bool,
};

export default MentalHealthChat;
