import {useEffect} from 'react';

export default function useMetaTags(brandName, metaTags) {
    useEffect(() => {
        const updateMetaTags = () => {
            const metaElements = document.head.querySelectorAll('meta');

            metaElements?.forEach(metaElement => {
                const name = metaElement.getAttribute('name');
                const property = metaElement.getAttribute('property');

                if (name === 'thumbnail') {
                    metaElement.setAttribute('content', metaTags.thumbnail);
                } else if (property === 'og:image') {
                    metaElement.setAttribute('content', metaTags.og_image);
                } else if (name === 'author') {
                    metaElement.setAttribute('content', brandName);
                } else if (property === 'og:url') {
                    metaElement.setAttribute('content', metaTags.og_url);
                } else if (name === 'description') {
                    metaElement.setAttribute(
                        'content',
                        `${brandName} provides telehealth appointments for primary care, non-emergency care, therapy and nutrition. Affordable, convenient care by phone or video.`
                    );
                } else if (property === 'og:title') {
                    metaElement.setAttribute(
                        'content',
                        `${brandName}® | #1 Telehealth & Telemedicine Provider`
                    );
                } else if (name === 'og:description') {
                    metaElement.setAttribute(
                        'content',
                        `${brandName} provides telehealth appointments for primary care, non-emergency care, therapy and nutrition. Affordable, convenient care by phone or video.`
                    );
                }
            });
        };

        updateMetaTags();
    }, [brandName, metaTags]);
}
