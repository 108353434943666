import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {cnb} from 'cnbuilder';
import mixpanel from 'mixpanel-browser';
import IconCheckCircleDefault from '@teladoc/pulse/icons/check-circle-default.svg';
import IconArrowRightDefault from '@teladoc/pulse/icons/arrow-right-default.svg';
import Link from '@teladoc/fe-ccm/ui/router/Link';
import {useTransLoader} from '@teladoc/fe-i18n';
import {CONDITION_MANAGEMENT, SIDEBAR_MENU} from '@/config';
import CommonUtils from '@/utils/common-utils';
import {MENTAL_HEALTH_PREFIX} from '@/router/routes';
import OneAppUserAPI from '@/user/user-oneapp-api';
import css from './Navigation.scss';

const NavLink = ({
    id,
    path,
    Icon,
    content,
    ariaRole,
    showSideBar,
    mixpanelObj,
    amplitudeProperty,
    onItemClick,
    iconClassName,
    itemClassName,
    setHeaderTitle,
    showNavigationIcon,
    activeItemClassName,
    ...rest
}) => {
    const {t} = useTransLoader('app');
    const {programs = []} = useSelector(state => state.user);
    const {branding} = useSelector(state => state.app);

    const onNavItemClick = evt => {
        if (id === 'logout') {
            evt.preventDefault();

            OneAppUserAPI.logout(programs, branding?.member_host);
        }

        if (onItemClick) {
            onItemClick(id);

            if (setHeaderTitle) {
                if (showSideBar) {
                    setHeaderTitle(t(`sidebar.navigation.${id}`));
                }

                if (
                    id === 'conditionManagement' ||
                    id === 'conditionManagementSidebar'
                ) {
                    setHeaderTitle(t(`sidebar.title.conditionManagement`));
                }
            }

            mixpanel.track(mixpanelObj?.event, mixpanelObj?.property);

            if (amplitudeProperty) {
                CommonUtils.setTrackingData(
                    amplitudeProperty?.productLine,
                    amplitudeProperty?.level,
                    amplitudeProperty?.button
                );
            }

            if (id === SIDEBAR_MENU.Weight) {
                evt.preventDefault();
            }
        }
    };

    const CCMActivePrograms = programs?.filter(
        route =>
            route.program !== 'BEHAVIORAL_HEALTH' && route.status === 'ACTIVE'
    );

    const renderCCMActiveProgram = evt => {
        return CCMActivePrograms.map(({slug}) => {
            return (
                <span key={slug} className={css.navProgramContent}>
                    <IconCheckCircleDefault className={css.navProgramIcon} />
                    {t(`header.programs.${slug}`)}
                </span>
            );
        });
    };

    const renderMenuItem = evt => {
        if (id === CONDITION_MANAGEMENT && CCMActivePrograms?.length === 1) {
            const programName = CCMActivePrograms[0].slug;

            return t(`header.navigation.${programName}`);
        }

        if (path.includes(MENTAL_HEALTH_PREFIX)) {
            return t('header.navigation.mentalHealth');
        }

        return t(`header.navigation.${id}`);
    };

    return (
        <Link
            id={id}
            className={cnb(css.navItem, itemClassName)}
            activeClassName={cnb(css.navItemActive, activeItemClassName)}
            to={path}
            data-id={id}
            onClick={onNavItemClick}
            role={ariaRole}
            {...rest}
        >
            {showSideBar ? (
                <>
                    {Icon && (
                        <Icon
                            className={cnb(css.navItemIcon, css.navCCMIcon)}
                        />
                    )}
                    {t(`sidebar.navigation.${id}`)}
                    {showNavigationIcon && (
                        <IconArrowRightDefault
                            className={cnb(
                                css.navRightIcon,
                                css.navSidebarIcon
                            )}
                        />
                    )}
                </>
            ) : (
                <div className={css.navContainer}>
                    {Icon && (
                        <Icon className={cnb(css.navItemIcon, iconClassName)} />
                    )}
                    <div className={css.navTextContainer}>
                        {renderMenuItem()}
                        {content && (
                            <span className={css.navItemContent}>
                                {t(`header.subnavigation.${content}`)}
                            </span>
                        )}
                        {CCMActivePrograms?.length > 1 &&
                            id === CONDITION_MANAGEMENT &&
                            renderCCMActiveProgram()}
                    </div>
                    {showNavigationIcon && (
                        <IconArrowRightDefault className={css.navRightIcon} />
                    )}
                </div>
            )}
        </Link>
    );
};

NavLink.propTypes = {
    id: PropTypes.string,
    path: PropTypes.string,
    Icon: PropTypes.object,
    showNavigationIcon: PropTypes.bool,
    ariaRole: PropTypes.string,
    content: PropTypes.string,
    onItemClick: PropTypes.func,
    iconClassName: PropTypes.string,
    activeItemClassName: PropTypes.string,
    itemClassName: PropTypes.string,
    setHeaderTitle: PropTypes.func,
    showSideBar: PropTypes.bool,
    mixpanelObj: PropTypes.object,
    amplitudeProperty: PropTypes.object,
};

export default NavLink;
