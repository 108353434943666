import PropTypes from 'prop-types';
import {cnb} from 'cnbuilder';
import {useRef} from 'react';
import HorizontalRule from '@teladoc/pulse/ui/HorizontalRule';
import KeyHandler from '@teladoc/fe-ccm/ui/common/utilities/key-handler';
import {NAV_MENU} from '@/config';
import NavLink from '../NavLink';
import parentClass from '../Navigation.scss';
import css from './SubNavigation.scss';

const SubNavigation = ({
    className,
    itemClassName,
    activeItemClassName,
    onItemClick,
    activeRoute,
    tabName,
}) => {
    const expandMenu = useRef(null);

    return (
        <ul
            ref={expandMenu}
            className={cnb(css.container, parentClass.root, className)}
            role="menu"
        >
            {activeRoute.map(
                ({
                    id,
                    path,
                    content,
                    isHorizontal,
                    mixpanelEvent,
                    mixpanelProperty,
                    amplitudeProperty,
                    icon: Icon,
                }) => (
                    <li key={id} role="none">
                        <NavLink
                            id={id}
                            path={path}
                            Icon={Icon}
                            content={content}
                            ariaRole="menuitem"
                            onItemClick={onItemClick}
                            mixpanelObj={{
                                event: mixpanelEvent,
                                property: mixpanelProperty,
                            }}
                            amplitudeProperty={amplitudeProperty}
                            itemClassName={cnb(itemClassName, {
                                [parentClass.navProgramText]:
                                    tabName === NAV_MENU.Programs.id,
                            })}
                            activeItemClassName={activeItemClassName}
                            iconClassName={cnb(
                                {
                                    [parentClass.navColorIcon]:
                                        tabName === NAV_MENU.Programs.id,
                                    [parentClass.navProgramIcon]:
                                        tabName !== NAV_MENU.Programs.id,
                                },
                                {[parentClass.navContentIcon]: content}
                            )}
                            name={`menu-item-${id}`}
                            onKeyDown={e =>
                                KeyHandler.keyDown(e, expandMenu?.current, true)
                            }
                        />
                        {isHorizontal && activeRoute?.length > 1 && (
                            <HorizontalRule />
                        )}
                    </li>
                )
            )}
        </ul>
    );
};

SubNavigation.propTypes = {
    className: PropTypes.string,
    onItemClick: PropTypes.func,
    itemClassName: PropTypes.string,
    activeItemClassName: PropTypes.string,
    activeRoute: PropTypes.array,
    tabName: PropTypes.string,
};

export default SubNavigation;
