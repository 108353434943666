import {Trans, useTranslation} from 'react-i18next';
import Image from '@teladoc/pulse/ui/Image';
import ScreenReader from '@teladoc/fe-ccm/ui/common/screen-reader/ScreenReader';
import CommonUtils from '@/utils/common-utils';
import Header from '@/Header';
import css from './Unavailable.scss';

const Unavailable = () => {
    const {t} = useTranslation();

    const getTranslationOrFallback = (key, fallback) => {
        const translation = t(key);
        const couldTranslate = translation !== key;
        const result = couldTranslate ? translation : fallback;

        return {result, couldTranslate};
    };
    const titleKey = 'errors.unavailable.title';
    const titleFallback = 'Uh-oh! Our site had a hiccup.';
    const {result: title, couldTranslate: couldTranslateTitle} =
        getTranslationOrFallback(titleKey, titleFallback);

    return (
        <>
            <Header />
            <div className={css.root} role="main">
                <Image
                    classNameRoot={css.image}
                    cloudinaryImageId="member-portal/common/error_tcmgli"
                    width={164}
                    height={117}
                    alt=""
                />
                <h1 className="heading-sm">{title}</h1>
                {couldTranslateTitle && (
                    <p>
                        <Trans
                            i18nKey="errors.oneapp.content"
                            components={[
                                <a
                                    href={`mailto:${process.env.MEMBER_SUPPORT_EMAIL}`}
                                    key={process.env.MEMBER_SUPPORT_EMAIL}
                                >
                                    <ScreenReader />
                                </a>,
                                <a
                                    href={`tel:${process.env.MEMBER_SUPPORT_PHONE}`}
                                    key={process.env.MEMBER_SUPPORT_PHONE}
                                >
                                    <ScreenReader />
                                </a>,
                            ]}
                            values={{
                                email: process.env.MEMBER_SUPPORT_EMAIL,
                                number: CommonUtils.formatPhoneNumber(
                                    process.env.MEMBER_SUPPORT_PHONE
                                ),
                            }}
                        />
                    </p>
                )}
                {!couldTranslateTitle && (
                    <p>
                        But good news ... we&apos;re working to get it back up
                        and running. In the meantime, try refreshing your
                        browser. If it happens again, email us at
                        <br />
                        <a href={`mailto:${process.env.MEMBER_SUPPORT_EMAIL}`}>
                            {process.env.MEMBER_SUPPORT_EMAIL}
                        </a>{' '}
                        or call
                        <br />
                        <a href={`tel:${process.env.MEMBER_SUPPORT_PHONE}`}>
                            {CommonUtils.formatPhoneNumber(
                                process.env.MEMBER_SUPPORT_PHONE
                            )}
                        </a>
                        .
                    </p>
                )}
            </div>
        </>
    );
};

export default Unavailable;
