import {APP_UPDATE} from './app-actions';

export const initialState = {
    // hasSafeContent is a boolean to show text content that makes no reference to hypertension or weight loss
    hasSafeContent: false,
    // hasFiveDayCheck handles if an account has the feature 5-day check or not
    hasFiveDayCheck: false,
    hasChatAvailable: false,
    hasMHGuideAvailable: false,
    hasCareTeam: false,
    // Weight Management gives access to features like setting a personal goal weight.
    hasWeightManagement: false,
    isScaleOnboardingDone: false,
    // hasTeladocSSOError is a boolean used  to  show an error message on the login page when the Teladoc SSO fails
    hasTeladocSSOError: false,
    isBloodPressureRetakeFromLink: false,
    isOneApp: false,
    isTeamsEnvironment: false,
    isRedirectFromTeams: false,
    hasTelemed: {},
    hasConditionManagement: false,
    hasMentalHealth: false,
    oneAppActiveProgram: '',
    oneAppActiveNav: '',
    isOneAppSidebar: false,
    breakpoint: '',
    weightScaleConfirmation: false,
    hasKidneyLabResults: false,
    hasOnboardingPrograms: false,
    branding: {},
};

export default function app(state = initialState, {type, payload}) {
    switch (type) {
        case APP_UPDATE: {
            return {
                ...state,
                ...payload,
            };
        }

        default:
            return state;
    }
}
