import PropTypes from 'prop-types';
import css from './Header.scss';

const SkipTo = ({skipToText, cssSelector}) => {
    const skipToContent = e => {
        e.preventDefault();
        const elm = document.querySelector(cssSelector);

        if (elm) {
            elm.setAttribute('tabindex', '0');
            elm.style.outline = 'none';
            elm.focus();
            elm.scrollIntoView();
        }
    };

    const onKeyDownHandler = e => {
        e.stopPropagation();

        if (e.nativeEvent.key === 'Enter' || e.nativeEvent.Key === 'Spacebar') {
            skipToContent(e, cssSelector);
        }
    };

    return (
        <a
            className={css.skipNavigation}
            onKeyDown={onKeyDownHandler}
            onClick={skipToContent}
            href="#mainContent"
        >
            {skipToText}
        </a>
    );
};

SkipTo.propTypes = {
    cssSelector: PropTypes.string.isRequired,
    skipToText: PropTypes.string.isRequired,
};

export default SkipTo;
